// import { TempWrapper } from '@components/app-layout/layout/mobile/app-layout-mobile-styled'
import { CookieNotification } from '@components/cookie-notification/cookie-notification'
// import { IS_DEV } from '@typings/constants'
import { Block } from '../../../../UI/block'
import { SiteLogo } from '../../../../UI/site-logo/site-logo'
import { LayoutProps } from '../../types'
import { ContentBlockWrapper, PageWrapper } from './login-mobile-styled'

export function LoginLayoutMobile({ children }: LayoutProps) {
	// if (IS_DEV) {
	return (
		<PageWrapper>
			<Block margin="32px 0">
				<SiteLogo color="#000000" size={43} />
			</Block>
			<ContentBlockWrapper>{children}</ContentBlockWrapper>
			<CookieNotification isMobileView />
			<div style={{ height: 200 }}></div>
		</PageWrapper>
	)
	// }

	// TODO: Remove this stub when the mobile layout is ready
	// return (
	// 	<TempWrapper>
	// 		<Block>
	// 			<SiteLogo color="#000000" size={120} />
	// 		</Block>
	// 		<Block>
	// 			Our mobile version is currently under development. In the meantime,
	// 			please use our desktop version for full access to all features.
	// 		</Block>
	// 		<Block>Thank you for your understanding and patience!</Block>
	// 	</TempWrapper>
	// )
}

import { CapsuleInfo } from '@typings/types'
import axios from 'axios'
import { axiosInstance } from '../../config'

type Props = {
	token: string
	stylistId: number
}

export async function getStylistSharedCapsules({
	token,
	stylistId,
}: Props): Promise<CapsuleInfo[] | undefined> {
	try {
		const response = await axiosInstance.get<any>(
			`/api/capsule/from-stylist/${stylistId}`,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		)

		return response.data
	} catch (error) {
		if (axios.isAxiosError(error)) {
			console.log('error message: ', error.message)
		} else {
			console.log('unexpected error: ', error)
		}
	}
}

import { Block, Button, Input, Skeleton } from '@ui/index'
import { FormWrapper, TitleWrapper, StyledP } from '../form-styled'
import { useEmailForm } from './use-email-form'

export function EmailForm() {
	const {
		errors,
		formRegister,
		isLoading,
		handleSubmit,
		onSubmitEmail,
		watch,
	} = useEmailForm()

	if (isLoading) {
		return (
			<Skeleton width={'100%'} height={352}>
				<rect x="0" y="0" rx="6" ry="6" width="326" height="32" />
				<rect x="0" y="56" rx="6" ry="6" width="326" height="66" />
				<rect x="0" y="146" rx="6" ry="6" width="326" height="94" />
				<rect x="0" y="296" rx="6" ry="6" width="326" height="56" />
			</Skeleton>
		)
	}

	return (
		<FormWrapper onSubmit={handleSubmit(onSubmitEmail)}>
			<Block isFlexBlock isContentCentered>
				<TitleWrapper>Hi, let's start!</TitleWrapper>
			</Block>
			<StyledP>
				Please put your e-mail here. If you are a new user, we will register
				you.
			</StyledP>
			<Block margin="56px 0 0px" />
			<Input
				label="email"
				hasValue={!!watch('email')}
				placeholder="E-mail address"
				inputFieldSlot={<input type="text" {...formRegister} />}
				isError={!!errors.email}
				errorText={errors.email?.message}
			/>
			<Button isSubmit skin="dark" margin="20px 0 0 0">
				Next
			</Button>
		</FormWrapper>
	)
}

import { Col, Row } from 'antd'

import { useResize } from '@hooks/use-resize'
import { colors } from '@styles/colors'
import { NavigationButtonInfo } from '@typings/types'
import { Icon } from '@ui/icon'
import {
	ButtonAll,
	ButtonDraft,
	ColBordered,
	ColRightAlign,
	DesktopContainer,
	IconWrapper,
	MobileContainer,
	SearchStyled,
} from './page-navigation-styled'

type Props = {
	showListType?: string
	isShowAllBtn?: boolean
	buttons?: NavigationButtonInfo[]
	handleChangeSearch?: (data: any) => void
	onFilterClick?: () => void
	leftSideSlot?: React.ReactNode
	rightSideSlot?: React.ReactNode
}

export function PageNavigation({
	showListType,
	isShowAllBtn = true,
	buttons,
	handleChangeSearch,
	onFilterClick,
	leftSideSlot,
	rightSideSlot,
}: Props) {
	const screenSize = useResize()

	return (
		<Row gutter={0} wrap={false} style={{ width: '100%' }}>
			<ColBordered span={24} isHideMobileBorder={!!handleChangeSearch}>
				<Row gutter={[16, 20]} style={{ height: '100%' }}>
					{leftSideSlot}
					{!leftSideSlot && !!buttons?.length && (
						<Col
							span={!!handleChangeSearch ? 12 : 20}
							style={{ display: 'flex' }}
						>
							{buttons.map((button, index) => {
								if (index === 0 && isShowAllBtn) {
									return (
										<ButtonAll
											key={index}
											isActive={showListType === button.showListType}
											onClick={button.handleClick}
											skin="grey-outline"
											isFitContent
											counter={button.counter}
											leftIcon={
												button.iconName && (
													<Icon
														color={
															showListType === button.showListType
																? '#FF0C63'
																: colors.blackTr64
														}
														name={button.iconName}
													/>
												)
											}
											rightIcon={
												button.showNotificationIcon && (
													<IconWrapper>
														<Icon
															color="white"
															name="bellRingingFilledIcon"
															size={9}
														/>
													</IconWrapper>
												)
											}
										>
											{button.title}
										</ButtonAll>
									)
								}

								return (
									<ButtonDraft
										key={index}
										color={button.color}
										isFitContent
										isActive={showListType === button.showListType}
										onClick={button.handleClick}
										skin="grey-outline"
										counter={button.counter}
										leftIcon={
											button.node
												? button.node
												: button.iconName && (
														<Icon
															color={
																showListType === button.showListType
																	? '#FF0C63'
																	: colors.blackTr64
															}
															name={button.iconName}
														/>
												  )
										}
										rightIcon={
											button.showNotificationIcon && (
												<IconWrapper>
													<Icon
														color="white"
														name="bellRingingFilledIcon"
														size={9}
													/>
												</IconWrapper>
											)
										}
									>
										{screenSize.isScreenXs && !!button.node ? '' : button.title}
									</ButtonDraft>
								)
							})}
						</Col>
					)}

					{rightSideSlot && (
						<ColRightAlign
							span={
								!leftSideSlot && !buttons?.length
									? 24
									: !!handleChangeSearch
									? 12
									: 4
							}
						>
							{rightSideSlot}
							{handleChangeSearch && (
								<DesktopContainer>
									<SearchStyled
										placeholder="Search"
										allowClear
										onChange={handleChangeSearch}
									/>
								</DesktopContainer>
							)}
						</ColRightAlign>
					)}
				</Row>
				{handleChangeSearch && (
					<MobileContainer>
						<SearchStyled
							placeholder="Search"
							allowClear
							onChange={handleChangeSearch}
						/>
					</MobileContainer>
				)}
			</ColBordered>
		</Row>
	)
}

import { colors } from '@styles/colors'
import styled from 'styled-components'

export const Wrapper = styled.aside`
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	padding: 20px;
	width: 90px;
	height: 100%;
	background: #000000;
	box-shadow: 0px 0px 42px rgba(78, 115, 156, 0.1);
	color: ${colors.white};

	z-index: 1000;
`

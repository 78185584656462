import { GrayLine } from '@ui/gray-line/gray-line'
import { Col, Row } from 'antd'
import {
	BlankContainer,
	CardImage,
	ColumnBlock,
	LinkText,
	RowStyled,
	StylistAbout,
	StylistAlignment,
	StylistInfoBlock,
	StylistName,
	Wrapper,
} from './stylist-card-styled'
import Avatar from '@components/avatar/avatar'
import { StylistInfo } from '@typings/types'
import { useNavigate } from 'react-router-dom'
import { PROD_DOMAIN } from '@typings/constants'
import { SiteLogo } from '@ui/site-logo/site-logo'
import { colors } from '@styles/colors'

type Props = {
	stylist: StylistInfo
}

export default function StylistCard({ stylist }: Props) {
	const navigate = useNavigate()
	const lastCapsuleCover = stylist.lastCapsuleCover
		? PROD_DOMAIN + stylist.lastCapsuleCover[0].urls.L
		: ''

	return (
		<Col xs={{span: 22}} md={{span: 11}} lg={{span: 8}}>
			<Wrapper
				onClick={() =>
					navigate(`/stylists/${stylist.user_id}`, {
						state: {
							stylistId: stylist.id,
							userId: stylist.user_id,
						},
					})
				}
			>
				<CardImage bgImage={lastCapsuleCover}>
					{!lastCapsuleCover && (
						<BlankContainer>
							<SiteLogo color={colors.blackTr16} size={100} isWithoutText />
						</BlankContainer>
					)}
				</CardImage>
				<StylistInfoBlock>
					<Row>
						<Avatar
							imageUrl={stylist.params?.photo?.[0].urls.L || ''}
							size={56}
						/>
						<ColumnBlock>
							<StylistName>
								{stylist.first_name} {stylist.last_name}
							</StylistName>
							{/*
							stylist.matchPercent && (
								<StylistAlignment>{`${stylist.matchPercent}% match`}</StylistAlignment>
							)
							*/}
							{stylist.notes && <StylistAbout>{stylist.notes}</StylistAbout>}
						</ColumnBlock>
					</Row>
					<GrayLine style={{ margin: '20px 0' }} />
					<RowStyled justify="end" align="middle">
						<LinkText>More details</LinkText>
					</RowStyled>
				</StylistInfoBlock>
			</Wrapper>
		</Col>
	)
}

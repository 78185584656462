import { Col, Image, Row } from 'antd'
import { useState } from 'react'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'

import { ImageItemType } from '@typings/types'
import {
	ArrowNext,
	ArrowPrev,
} from '@ui/gallery-buttons/gallery-buttons-styled'
import { Icon } from '@ui/icon'
import { useNavigate, useParams } from 'react-router-dom'
import { FullScrIcon } from '../capsules-preview-page-styled'
import {
	Brand,
	CarouselWrapper,
	Container,
	HoverWrapper,
	InfoWrapper,
	Price,
	Title,
} from './gallery-block-styled'

type Props = {
	carouselRef: any
	imageList: ImageItemType[]
}

export function GalleryBlock({ carouselRef, imageList }: Props) {
	const navigate = useNavigate()
	const [visible, setVisible] = useState(false)
	const { id: capsuleId } = useParams()

	const responsive = {
		superLargeDesktop: {
			// the naming can be any, depends on you.
			breakpoint: { max: 4000, min: 1900 },
			items: 3,
		},
		desktop: {
			breakpoint: { max: 1899, min: 1400 },
			items: 2,
		},
		tablet: {
			breakpoint: { max: 1399, min: 1200 },
			items: 2,
			partialVisibilityGutter: 155,
		},
		mobile: {
			breakpoint: { max: 464, min: 0 },
			items: 1,
		},
	}

	return (
		<Row gutter={0} align="middle">
			<Col span={18} className="row-block-label">
				Clothing items
			</Col>
			<Col span={6} />

			<Col xs={{ span: 3 }} sm={{ span: 2 }}>
				{imageList.length > 1 && (
					<ArrowPrev
						leftIcon={<Icon color="#FFF" name="arrowDownIcon" />}
						onClick={() => carouselRef.current?.previous()}
						skin="white-outline"
						size="m"
						isFitContent
					/>
				)}
			</Col>
			<Col xs={{ span: 18 }} sm={{ span: 20 }} style={{ marginTop: 58 }}>
				<CarouselWrapper>
					<Carousel
						ref={(el) => (carouselRef.current = el)}
						responsive={responsive}
						containerClass="carousel-container"
						itemClass="carousel-item"
						centerMode={false}
						arrows={false}
						renderButtonGroupOutside={true}
					>
						{imageList.map((imageItem, index) => (
							<Container
								key={index}
								onClick={() => {
									if (visible) return
									imageItem.itemId &&
										navigate('/clothes/' + imageItem.itemId, {
											state: {
												capsuleId,
											},
										})
								}}
							>
								<HoverWrapper>
									<Image
										width={'100%'}
										src={imageItem.url}
										preview={{
											src: imageItem.previewUrl,
											mask: (
												<FullScrIcon>
													<Icon color="white" name="fullScreenIcon" />
												</FullScrIcon>
											),
											onVisibleChange: (value) => {
												setVisible(value)
											},
										}}
										onClick={(e) => {
											e.stopPropagation()
											setVisible(true)
										}}
										style={{ height: '100%' }}
									/>
								</HoverWrapper>
								<InfoWrapper>
									<Title>{imageItem.title}</Title>
									<Brand>{imageItem.brand}</Brand>
									<Price>
										{imageItem.price &&
											`${imageItem.currency} ${imageItem.price}`}
									</Price>
								</InfoWrapper>
							</Container>
						))}
					</Carousel>
				</CarouselWrapper>
			</Col>
			<Col xs={{ span: 3 }} sm={{ span: 2 }}>
				{imageList.length > 1 && (
					<ArrowNext
						leftIcon={<Icon color="#FFF" name="arrowDownIcon" />}
						onClick={() => carouselRef.current?.next()}
						skin="white-outline"
						size="m"
						isFitContent
					/>
				)}
			</Col>
		</Row>
	)
}

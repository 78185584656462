import { Col, Flex, Row } from 'antd'

import { FullScrIcon } from '@pages/capsules/capsules-preview-page/capsules-preview-page-styled'
import { QuizSelector } from '@store/quiz'
import { useAppSelector } from '@store/store'
import { userSelector } from '@store/user'
import { PROD_DOMAIN } from '@typings/constants'
import { Block, Icon } from '@ui/index'
import {
	AnswerCont,
	AnswerWrapper,
	IconBorder,
	ImageStyled,
	ImageWrapper,
	QuestionCont,
	RowFlexColumn,
	RowStyled,
	TextWrapper,
} from '../../quiz-styled'
import { useQuizAnswers } from '../../use-quiz-answers'
import { useResize } from '@hooks/use-resize'

export function QuizStyleQuestion() {
	const { userInfo } = useAppSelector(userSelector)
	const screenSize = useResize()

	const { questions, currentStep, answers } = useAppSelector(QuizSelector)
	const currentQuestion = questions[currentStep - 1]
	const currentAnswers = answers[currentStep] ?? ''
	const { handleSaveAnswer } = useQuizAnswers({
		isMultiAnswer: !!currentQuestion?.answers_multiple,
	})
	const questionImage = currentQuestion?.cover_image
		? PROD_DOMAIN + currentQuestion?.cover_image
		: ''
	const answerLen = currentQuestion?.answers_json.length

	if (!currentQuestion?.answers_json.length) return null

	return (
		<RowStyled style={{ height: '100%' }}>
			<Col
				span={24}
				style={{
					justifyContent: 'center',
					display: 'flex',
					flexFlow: 'column',
				}}
			>
				<Row justify="center" align="middle" gutter={[8, 24]}>
					<Col xs={{ span: 22 }} md={{ span: 14 }} lg={{ span: 14 }}>
						<QuestionCont>
							{userInfo.role === 'stylist'
								? currentQuestion?.stylist_question
								: currentQuestion?.question}
						</QuestionCont>
					</Col>

					{currentQuestion.step_comment && (
						<Col xs={{ span: 22 }} md={{ span: 14 }} lg={{ span: 14 }}>
							<TextWrapper>{currentQuestion.step_comment}</TextWrapper>
						</Col>
					)}
					{questionImage && (
						<Col xs={{ span: 22 }} md={{ span: 20 }} lg={{ span: 12 }}>
							<ImageStyled
								loading="eager"
								src={questionImage}
								width="100%"
								preview={{
									mask: (
										<FullScrIcon>
											<Icon color="white" name="fullScreenIcon" />
										</FullScrIcon>
									),
								}}
							/>
						</Col>
					)}
				</Row>

				<RowFlexColumn
					justify="center"
					align="middle"
					gutter={[8, 24]}
					style={{ margin: '20px auto' }}
				>
					{currentQuestion.answers_json.map((answer, index) => {
						return (
							<Col
								xs={{ span: answer.image || answerLen > 3 ? 13 : 8 }}
								md={{ span: answer.image ? 12 : answerLen > 3 ? 4 : 5 }}
								lg={{ span: answer.image ? 6 : 4 }}
								xl={{ span: answer.image ? 5 : 4 }}
								xxl={{ span: answer.image ? 4 : answerLen > 3 ? 3 : 4 }}
								key={index}
								onClick={() => handleSaveAnswer(answer.value)}
							>
								<AnswerCont>
									{answer.image && (
										<ImageWrapper>
											<img src={PROD_DOMAIN + answer.image} alt="" />
										</ImageWrapper>
									)}
									<Block
										isContentCentered
										justifyContent="center"
										isFullWidth
										isFlexBlock
										flexDirection={
											answer.image || answerLen > 3 ? 'column' : 'row'
										}
										gap={answer.image || answerLen > 3 ? '24px' : '8px'}
										padding={answerLen > 3 && screenSize.isScreenXs && !answer.image ? '32px 0' : 'unset'}
									>
										<IconBorder>
											{currentAnswers.includes(answer.value) && (
												<Icon name="checkCircleColoredIcon" color="#FF0C63" />
											)}
										</IconBorder>
										<AnswerWrapper>{answer.text}</AnswerWrapper>
									</Block>
								</AnswerCont>
							</Col>
						)
					})}
				</RowFlexColumn>
			</Col>
		</RowStyled>
	)
}

import styled from 'styled-components'
import {
	fontTenorSans,
	fontRoboto,
	regular16,
	regular26,
	regular14,
} from '@styles/typography'
import { colors } from '@styles/colors'
import { screenSize } from '@styles/screen-sizes'

export const FormWrapper = styled.form`
	display: flex;
	flex-direction: column;

	.styledyle-input-paddings {
		${fontRoboto}
		color: ${colors.blackTr64};
		width: 48%;

		@media (${screenSize.antXs}) {
			${regular14}
			height: 42px;
			padding: 12px;
		}
		@media (${screenSize.antSm}) {
			${regular14}
			height: 42px;
			padding: 12px;
		}
		@media (${screenSize.antLg}) {
			${regular16}
			height: 56px;
			padding: 18px 16px;
		}
	}		

	.ant-picker .ant-picker-input > input {
		font-size: unset;
		line-height: unset;
		color: unset;
		color: ${colors.black};
	}
	.ant-picker .ant-picker-input > input::placeholder {
		color: unset !important;
	}
	/* 
	.ant-select-single {
	}
	.ant-select-single .ant-select-selector .ant-select-selection-item {
		line-height: 2.2rem;
	}
	.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
		height: 60px;
		padding: 18px 16px;
		${fontRoboto}
		${regular16}
		color: ${colors.black};
	}
	.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
		line-height: unset;
	}

	.ant-select-arrow {
		color: ${colors.black};
	}
	*/
`

export const RowTitle = styled.div`
	${fontTenorSans}
	${regular26}
`

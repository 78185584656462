import { Col, Row } from 'antd'
import { useMediaQuery } from 'react-responsive'

import { CapsulesItem } from '@components/capsules-item'
import { ItemsList } from '@components/items-list/items-list'
import { PageNavigation } from '@components/page-navigation/page-navigation'
import { Block, ButtonLinkTo, Icon, Skeleton } from '@ui/index'
import { useCapsulesPage } from './use-capsules-page'

const CAPSULE_SIZE = {
	width: 393,
	height: 556,
}

export function CapsulesPage() {
	const {
		capsulesList,
		handleAddNewCapsule,
		handleCapsuleClick,
		handleChangeSearch,
		isListLoading,
		buttons,
		showListType,
	} = useCapsulesPage()

	const isXxl = useMediaQuery({
		query: '(min-width: 1600px)',
	})
	const itemsPerPage = isXxl ? 4 : 3
	const loadersArr = new Array(itemsPerPage * 2 - 1).fill(1)

	return (
		<Block isFullWidth margin="0 48px 48px">
			<PageNavigation
				showListType={showListType}
				buttons={buttons}
				handleChangeSearch={handleChangeSearch}
				rightSideSlot={<></>}
			/>
			<Row gutter={[40, 20]}>
				<Col xxl={{ span: 6 }} xl={{ span: 8 }}>
					<ButtonLinkTo
						onClick={handleAddNewCapsule}
						minHeight="493px"
						maxWidth={CAPSULE_SIZE.width + 'px'}
						title="Add Capsule"
						icon={<Icon name="hangerAddIcon" size={72} />}
					/>
				</Col>
				<ItemsList
					isLoading={isListLoading}
					itemsList={capsulesList}
					loaderEl={
						<>
							{loadersArr.map((value, index) => (
								<Col xxl={{ span: 6 }} xl={{ span: 8 }} key={index}>
									<Skeleton width="100%" height={CAPSULE_SIZE.height}>
										<rect
											x="0"
											y="0"
											rx="20"
											ry="20"
											width="100%"
											height={CAPSULE_SIZE.height}
										/>
									</Skeleton>
								</Col>
							))}
						</>
					}
					renderItem={(item) => (
						<Col key={item.id} xxl={{ span: 6 }} xl={{ span: 8 }}>
							<CapsulesItem
								item={item}
								onClick={handleCapsuleClick}
								maxWidth={CAPSULE_SIZE.width + 'px'}
							/>
						</Col>
					)}
				/>
			</Row>
		</Block>
	)
}

import styled from 'styled-components'

import { colors, screenSize } from '@styles/index'
import { fontRoboto, bold14, regular13 } from '@styles/typography'

export const TipTextBlock = styled.div`
	padding: 9px 12px 9px 24px;
	margin-left: -12px;
	background: ${colors.blackTr5};
	border-radius: 12px;
	${fontRoboto}
	text-align: left;

	& span {
		color: ${colors.black};
	}

	& a {
		color: #ff3e5b;
		text-decoration: underline;
	}

	@media (${screenSize.antXs}) {
		${regular13}
	}
	@media (${screenSize.antSm}) {
		${bold14}
	}
`

export const LogoCircle = styled.div`
	width: 36px;
	height: 36px;
	padding: 6px;
	border-radius: 50%;
	background: ${colors.black};
	align-self: start;
	margin-top: 5px;
`

import { colors, fontRoboto, regular14, regular16, screenSize } from '@styles/index'
import styled from 'styled-components'

export const FormWrapper = styled.form`
	display: flex;
	flex-direction: column;

	.styledyle-input-paddings {
		${fontRoboto}
		color: ${colors.blackTr64};
		width: 48%;

		@media (${screenSize.antXs}) {
			${regular14}
			height: 42px;
			padding: 12px;
		}
		@media (${screenSize.antSm}) {
			${regular14}
			height: 42px;
			padding: 12px;
		}
		@media (${screenSize.antLg}) {
			${regular16}
			height: 56px;
			padding: 18px 16px;
		}
	}

	.ant-picker .ant-picker-input > input {
		font-size: unset;
		line-height: unset;
		color: unset;
		color: ${colors.black};
	}
	.ant-picker .ant-picker-input > input::placeholder {
		color: unset !important;
	}
`

export const TitleBlock = styled.div`
	font-family: 'Tenor Sans';
	font-size: 26px;
	font-style: normal;
	font-weight: 400;
	line-height: 32px; /* 123.077% */

	color: ${colors.black};

	margin-right: 20px;
`

export const ContentBlock = styled.div`
	display: flex;
	gap: 36px 0;
	flex-wrap: wrap;
	align-items: center;
	margin-top: 36px;
`

import { screenSize } from '@styles/screen-sizes'
import styled from 'styled-components'

export const MenuWrapper = styled.nav`
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	height: 100%;
	margin: 70px 0;

	@media (${screenSize.antXl}) {
		margin-top: 70px;
		justify-content: space-between;
	}
`

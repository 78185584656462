import styled, { css } from 'styled-components'

import { colors } from '@styles/colors'
import { Block } from '@ui/block'

export const Wrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	width: 100%;
	justify-content: space-between;
	align-items: center;
	background-color: ${colors.black};
	box-shadow: 0px 0px 42px rgba(78, 115, 156, 0.1);
	padding: 8px 12px;
	color: ${colors.white};

	z-index: 110;
`
export const ProfileWrapper = styled.div<{ imageUrl?: string }>`
	display: flex;
	justify-content: center;
	align-items: center;

	width: 32px;
	height: 32px;
	margin-left: 20px;
	border-radius: 50%;
	background: rgba(255, 255, 255, 0.24);

	${({ imageUrl }) =>
		imageUrl &&
		css`
			background-image: url(${imageUrl});
			box-shadow: 0px 0px 0px 4px rgba(255, 255, 255, 0.24);
		`}
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
`

export const ProfileImage = styled.div<{ imageUrl?: string }>`
	display: flex;
	justify-content: center;
	align-items: center;

	width: 32px;
	height: 32px;
	border-radius: 50%;
`

export const RightBlock = styled(Block)`
	justify-content: end;
`

export const ProfileArrowBlock = styled(Block)`
	position: relative;
	cursor: pointer;
`

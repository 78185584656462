import { Col, Image, Row } from 'antd'
import moment from 'moment'
import { useRef } from 'react'

import { PageNavigation } from '@components/page-navigation/page-navigation'
import { PROD_DOMAIN } from '@typings/constants'
import { Block } from '@ui/block'
import { Button } from '@ui/button'
import { GrayLine } from '@ui/gray-line/gray-line'
import { Icon } from '@ui/icon'
import { Spinner } from '@ui/spinner'
import { Swap } from '@ui/swap'
import {
	CategoryItem,
	CategoryList,
	ColorCircle,
	ColorWrapper,
	Price,
} from '../../capsules/capsules-edit-page/capsules-edit-page-styled'
import {
	CapsuleCollageCont,
	CapsuleCollageWrapper,
	CapsuleTitle,
	ColParamLabel,
	CreatedDate,
	FullScrIcon,
} from './client-capsules-preview-page-styled'
import { GalleryBlock } from './gallery-block/gallery-block'
import { ReviewButtons } from './review-buttons/review-buttons'
import { useClientCapsulePreviewPage } from './use-client-capsules-preview-page'

export function ClientCapsulesPreviewPage() {
	const {
		token,
		shareLink,
		isShowAllCategories,
		isCapsuleLoading,
		capsule,
		imageItems,
		settings,
		categoriesToShow,
		isShortCategoriesList,
		navigationButtons,
		handleToggleCategoriesList,
		handleAcceptClick,
		handleRestyleClick,
	} = useClientCapsulePreviewPage()

	const carouselRef = useRef<any>()

	const renderCategories = () => (
		<CategoryList>
			{categoriesToShow.map(([key, value], index) => {
				return <CategoryItem key={index}>{`${key} (${value})`}</CategoryItem>
			})}
			{isShowAllCategories && (
				<Button
					skin="light-link"
					isFitContent
					size="s"
					onClick={handleToggleCategoriesList}
				>
					{isShortCategoriesList ? 'Show All' : 'Hide'}
				</Button>
			)}
		</CategoryList>
	)

	const renderColors = () => {
		const colors = capsule.summary?.color
		const storeColors = settings.ItemColors

		if (!colors) return null

		return (
			<ColorWrapper>
				{Object.entries(colors).map(([key, value]) => {
					return (
						<ColorCircle key={key} color={storeColors?.[key]}></ColorCircle>
					)
				})}
			</ColorWrapper>
		)
	}

	const coverImageM = capsule.params?.coverPhoto
		? PROD_DOMAIN + capsule.params?.coverPhoto?.[0].urls.M
		: ''
	const coverImageL = capsule.params?.coverPhoto
		? PROD_DOMAIN + capsule.params?.coverPhoto?.[0].urls.L
		: ''

	return (
		<Block isFullWidth>
			{token && (
				<PageNavigation
					isShowAllBtn={false}
					buttons={navigationButtons}
					rightSideSlot={
						<ReviewButtons
							shareLink={shareLink}
							status={capsule.dealStatus}
							onAcceptClick={handleAcceptClick}
							onRestyleClick={handleRestyleClick}
						/>
					}
				/>
			)}
			<Swap is={isCapsuleLoading} isSlot={<Spinner />}>
				<Row gutter={{xs: 8, sm: 12, md: 24, lg: 48 }}>
					<Col
						xxl={{ span: 6 }}
						xl={{ span: 7 }}
						lg={{ span: 8 }}
						md={{ span: 11, offset: 0 }}
						xs={{ span: 22, offset: 1 }}
					>
						<CapsuleCollageWrapper>
							<CapsuleTitle>{capsule.title}</CapsuleTitle>
							<CapsuleCollageCont>
								<Image
									src={coverImageM}
									width="100%"
									preview={{
										src: coverImageL,
										mask: (
											<FullScrIcon>
												<Icon color="white" name="fullScreenIcon" />
											</FullScrIcon>
										),
									}}
								/>
							</CapsuleCollageCont>
						</CapsuleCollageWrapper>
					</Col>
					<Col
						xxl={{ span: 6 }}
						xl={{ span: 7, offset: 0 }}
						md={{ span: 12, offset: 1 }}
						xs={{ span: 22, offset: 1 }}
						style={{ paddingTop: 32 }}
					>
						<Row gutter={12}>
							<Col flex="0 1 170px" className="row-block-label">
								Description
							</Col>
						</Row>
						<Row gutter={24} style={{ marginTop: 48 }}>
							<ColParamLabel span={8}>Category</ColParamLabel>
							<Col span={16}>{renderCategories()}</Col>
							<Col span={24} style={{ marginTop: 24, marginBottom: 24 }}>
								<GrayLine />
							</Col>
							<ColParamLabel span={8}>Colours</ColParamLabel>
							<Col span={16}>{renderColors()}</Col>
							<Col span={24} style={{ marginTop: 24, marginBottom: 24 }}>
								<GrayLine />
							</Col>
							<ColParamLabel span={8}>Created</ColParamLabel>
							<Col span={16}>
								<CreatedDate>
									{moment(capsule.created_at).format('DD, MMM YYYY')}
								</CreatedDate>
							</Col>
							<Col span={24} style={{ marginTop: 24, marginBottom: 24 }}>
								<GrayLine />
							</Col>
							<ColParamLabel span={8}>Overall budget</ColParamLabel>
							<Col span={16}>
								<Price>
									<p>{capsule.summary?.priceValute}</p>
									<p>{capsule.summary?.price}</p>
								</Price>
							</Col>
						</Row>
					</Col>
					<Col
						xxl={{ span: 12 }}
						xl={{ span: 10, offset: 0 }}
						md={{ span: 24, offset: 0 }}
						xs={{ span: 22, offset: 1 }}
						style={{ paddingTop: 32 }}
					>
						<GalleryBlock
							carouselRef={carouselRef}
							items={capsule.items}
							imageList={imageItems}
						/>
					</Col>
				</Row>
			</Swap>
		</Block>
	)
}

import { screenSize } from '@styles/screen-sizes'
import styled from 'styled-components'

export const Container = styled.div`
	display: flex;
	height: 100%;
	width: inherit;

	@media (${screenSize.antXs}) {
		padding: 0;
		flex-direction: column;
		gap: 24px;
	}
	@media (${screenSize.antSm}) {
		padding: 0;
		flex-direction: column;
		gap: 24px;
	}	
	@media (${screenSize.antXl}) {
		flex-direction: row;
		gap: 40px;
	}
`

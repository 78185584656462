import { ReactNode } from 'react'
import { useMediaQuery } from 'react-responsive'

import { size } from '@styles/screen-sizes'
import { IS_DEV } from '@typings/constants'

type Props = {
	mobileSlot: ReactNode
	desktopSlot: ReactNode
}

export function MediaLayout({ mobileSlot, desktopSlot }: Props) {
	const userAgent = navigator.userAgent.toLowerCase()
	const isMobile =
		/mobile|iphone|ipad|ipod|android|blackberry|mini|windows\sce|palm/i.test(
			userAgent
		)

	const isDesktop = useMediaQuery({
		query: `(min-width: ${size.antXl})`,
	})

	if (!IS_DEV && !isMobile) {
		return <>{desktopSlot}</>
	}

	if (isDesktop) {
		return <>{desktopSlot}</>
	}

	return <>{mobileSlot}</>
}

import { useRef } from 'react'

import { useOutsideAlerter } from '@hooks/use-outside-click'
import { LeftMenu } from '../../left-menu/left-menu'
import { SideBarProps } from '../../types'
import { Wrapper } from './left-sidebar-mobile-styled'

export function LeftSidebarMobile({ isOpen, handleClose }: SideBarProps) {
	const wrapperRef = useRef(null)
	useOutsideAlerter(wrapperRef, handleClose!)

	if (!isOpen) return null

	return (
		<Wrapper ref={wrapperRef}>
			<LeftMenu handleClose={handleClose} />
		</Wrapper>
	)
}

import { sizeNum } from '@styles/screen-sizes';
import { useState, useEffect } from 'react';

export const useResize = () => {
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = (event: any) => {
        setWidth(event.target.innerWidth);
    };
        window.addEventListener('resize', handleResize);
        return () => {
        window.removeEventListener('resize', handleResize);
    };
    }, []);

    return {
        isScreenXs: width <= sizeNum.antSm,
        isScreenSm: width >= sizeNum.antSm,
        isScreenMd: width >= sizeNum.antMd,
        isScreenLg: width >= sizeNum.antLg,
        isScreenXl: width >= sizeNum.antXl,
        isScreenXxl: width >= sizeNum.antXXl,
    };
};
import { QuizEnd } from '@components/quiz/quiz-end/quiz-end'
import { Block } from '@ui/index'

import { QuizCol, RowStyled } from './quiz-result-page-styled'

export function QuizResultPage() {
	return (
		<>
			<Block isFullWidth>
				<RowStyled align="middle" justify="center">
					<QuizCol className="results-frame">
						<QuizEnd />
					</QuizCol>
				</RowStyled>
			</Block>
		</>
	)
}

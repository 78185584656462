import styled from 'styled-components'
import { fontTenorSans, fontRoboto } from '@styles/typography'
import { screenSize } from '@styles/screen-sizes'

export const FormWrapper = styled.form`
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 326px;
	margin: 0 auto;
`
export const FormWrapperUserCreate = styled.form`
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 482px;
	margin: 0 auto;
`

export const ButtonsBlock = styled.div`
	display: flex;
	justify-content: space-between;

	@media (${screenSize.antXs}) {
		margin-top: 24px;
	}
	@media (${screenSize.antSm}) {
		margin-top: 48px;
	}
`

export const TitleWrapper = styled.h1`
	${fontTenorSans}
	font-weight: 400;
	font-size: 26px;
	line-height: 32px;
	margin: 0;
`

export const StyledP = styled.p`
	${fontRoboto}
	font-weight: 400;
	text-align: center;
	margin: 24px auto 0;
	font-size: 16px;
	line-height: 22px;
	opacity: 0.64;

	@media (${screenSize.antXs}) {
		margin-bottom: 24px;
	}
	@media (${screenSize.antSm}) {
		margin-bottom: 48px;
	}
`

import { Col, Image } from 'antd'
import styled, { createGlobalStyle, css } from 'styled-components'

import { colors } from '@styles/colors'
import {
	bold14,
	fontRoboto,
	fontTenorSans,
	regular13,
	regular32,
} from '@styles/typography'
import { Block } from '@ui/block'
import { Button } from '@ui/button'

export const ImageWrapper = styled(Image)`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	border-radius: 20px;
	overflow: hidden;
`

export const CoverImageWrapper = styled.div`
	padding: 36px 20px;
	margin: auto;

	max-width: 393px;
	height: 612px;
	overflow: hidden;

	background: #ffffff;
	box-shadow: 0px 6px 32px ${colors.blackTr8};
	border-radius: 20px;

	& img {
		object-fit: contain;
	}
`

export const ColParamLabel = styled(Col)`
	${fontRoboto}
	${regular13}
  color: ${colors.blackTr48};
	align-self: center;
`

export const ParamValue = styled.div`
	${fontRoboto}
	${bold14}
  color: ${colors.blackTr64};
	display: inline-block;
	max-width: 172px;
	padding: 10px 16px;
	margin-right: 16px;
	border: 1px solid ${colors.blackTr16};
	border-radius: 8px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`

export const PriceValue = styled.div`
	${fontTenorSans}
	${regular32}
`

export const CopyLinkButton = styled(Button)`
	width: 20%;
	vertical-align: middle;
	padding: unset;

	&:hover {
		background-color: ${colors.blackTr5};
	}

	&:active {
		background-color: ${colors.blackTr16};
	}
`
export const ColFlex = styled(Col)`
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;
`
export const ImageCont = styled.div<{ imageUrl?: string }>`
	display: flex;
	justify-content: center;
	align-items: center;
	max-width: 393px;
	width: 100%;
	height: 100%;
	border-radius: 12px;

	${({ imageUrl }) =>
		imageUrl &&
		css`
			background-image: url(${imageUrl});
			box-shadow: none;
		`}

	background-color: ${colors.blackTr5};
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
`

export const ImageStyled = styled(Image)`
	max-width: 393px;
	width: 100%;
	height: 100%;
	border-radius: 20px;
	background-color: ${colors.blackTr5};
	object-fit: contain;
	margin: auto;
	overflow: hidden;
`

export const FullScrIcon = styled.div`
	position: absolute;
	top: 26px;
	left: 26px;
	width: 48px;
	height: 48px;
	background: linear-gradient(180deg, #ff0c63 0%, #ff5656 100%);
	border-radius: 8px;
	padding: 12px;
`

export const InputClothLink = styled.input`
	${fontRoboto}
	${bold14}
	color: ${colors.blackTr64};
	display: inline-block;
	width: 100%;
	padding: 10px 16px;
	margin-right: 6px;
	border: 1px solid ${colors.blackTr16};
	border-radius: 8px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	&:focus-visible {
		outline: unset;
	}
`

export const AntCSSlStyle = createGlobalStyle`
  .ant-image-preview-operations .ant-image-preview-operations-operation-rotateLeft,
  .ant-image-preview-operations .ant-image-preview-operations-operation-rotateRight,
  .ant-image-preview-operations .ant-image-preview-operations-operation-zoomOut,
  .ant-image-preview-operations .ant-image-preview-operations-operation-zoomIn {
    display: none;
  }

  .ant-image-mask {
    border-radius: 20px;
  }
`

export const StyledBlock = styled(Block)`
	display: flex;
	gap: 48px;
`

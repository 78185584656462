import { Button } from '@ui/button'
import styled from 'styled-components'

export const ArrowPrev = styled(Button)`
	margin: auto;
	padding: 16px 2px;
	background: linear-gradient(180deg, #ff0c63 0%, #f56565 100%);
	border: none;
	transition: none;
	z-index: 100000;
	max-height: 54px;

	> div {
		margin-right: 0;
		transform: rotate(90deg);
	}

	&:hover {
		background: #ff0c63;
	}
`

export const ArrowNext = styled(Button)`
	margin: auto;
	padding: 16px 2px;
	background: linear-gradient(180deg, #ff0c63 0%, #f56565 100%);
	border: none;
	transition: none;
	z-index: 100000;
	max-height: 54px;

	> div {
		margin-right: 0;
		transform: rotate(270deg);
	}

	&:hover {
		background: #ff0c63;
	}
`

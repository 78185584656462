import { Outlet } from 'react-router-dom'

import { Footer } from '@components/app-layout/footer/footer'
import { LeftSidebar } from '@components/app-layout/left-sidebar/left-sidebar'
import { Chat } from '@components/chat/chat'
import { CookieNotification } from '@components/cookie-notification/cookie-notification'
import { useToggle } from '@hooks/use-toggle'
// import { IS_DEV } from '@typings/constants'
// import { Block } from '@ui/block'
// import { SiteLogo } from '@ui/site-logo'
import { Header } from '../../header/header'
import {
	ChildrenWrapper,
	Content,
	// TempWrapper,
	Wrapper,
} from './app-layout-mobile-styled'

export function AppLayoutMobile() {
	const { isOpen, handleClose, handleToggle } = useToggle()

	// if (IS_DEV) {
	return (
		<Wrapper>
			<LeftSidebar isOpen={isOpen} handleClose={handleClose} />
			<Content>
				<Header onMenuToggle={handleToggle} />
				<ChildrenWrapper>
					<Outlet />
					<CookieNotification isMobileView />
				</ChildrenWrapper>
				<Chat />
				<Footer />
			</Content>
		</Wrapper>
	)
	// }

	// TODO: Remove this stub when the mobile layout is ready
	// return (
	// 	<TempWrapper>
	// 		<Block>
	// 			<SiteLogo color="#000000" size={120} />
	// 		</Block>
	// 		<Block>
	// 			Our mobile version is currently under development. In the meantime,
	// 			please use our desktop version for full access to all features.
	// 		</Block>
	// 		<Block>Thank you for your understanding and patience!</Block>
	// 	</TempWrapper>
	// )
}

import { Col, Row } from 'antd'
import styled from 'styled-components'

import { screenSize } from '@styles/index'
import { Block } from '@ui/block'

export const BlockStyled = styled(Block)`
	@media (${screenSize.antXs}) {
		padding: 0;
		height: 100%;
		min-height: calc(100vh - 200px);
	}

	@media (${screenSize.antSm}) {
		padding: 0;
		height: 100%;
		min-height: unset;
	}

	@media (${screenSize.antLg}) {
		padding: 0 24px 24px;
	}
	@media (${screenSize.antXl}) {
		padding: 0 48px 8px;
	}
`

export const RowStyled = styled(Row)`
	flex-flow: column;
	height: 100%;
`

export const QuizCol = styled(Col)`
	width: 100%;
	display: flex;
	flex-flow: column;
	justify-content: flex-start;
	flex: 1 1 50%;

	@media (${screenSize.antXs}) {
		justify-content: space-around;
	}

	@media (${screenSize.antMd}) {
		margin-top: 60px;
		justify-content: space-between;
	}

	@media (${screenSize.antLg}) {
		margin-top: 0;
		justify-content: space-between;
	}

	@media (${screenSize.antXl}) {
		justify-content: space-around;
	}

	&.results-frame {
		flex: unset;
	}
`

import { Block } from '@ui/block'
import { Icon } from '@ui/icon'
import 'react-multi-carousel/lib/styles.css'
import { ArrowNext, ArrowPrev } from './gallery-buttons-styled'

type Props = {
	onPrevArrowClick: () => void
	onNextArrowClick: () => void
}

export function GalleryButtons({ onPrevArrowClick, onNextArrowClick }: Props) {
	return (
		<Block isFlexBlock justifyContent="end">
			<ArrowPrev
				leftIcon={<Icon color="#FFF" name="arrowDownIcon" />}
				onClick={onPrevArrowClick}
				skin="white-outline"
				size="m"
				isFitContent
			/>
			<ArrowNext
				leftIcon={<Icon color="#FFF" name="arrowDownIcon" />}
				onClick={onNextArrowClick}
				skin="white-outline"
				size="m"
				isFitContent
			/>
		</Block>
	)
}

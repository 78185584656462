import { colors } from '@styles/colors'
import { bold14, fontRoboto } from '@styles/typography'
import { Button } from 'antd'
import styled from 'styled-components'

export const Container = styled.div`
	display: flex;
	justify-content: end;
	align-items: center;
	gap: 8px;
	width: 100%;

	padding: 12px;

	> div {
		display: flex;
		align-items: center;
		font-size: 16px;
		font-family: Roboto;
		font-style: normal;
		font-weight: 700;
		line-height: 20px;
		color: ${colors.blackTr64};

		> span > span {
			color: ${colors.mainThemeColor};
		}
	}
`

export const AcceptButton = styled(Button)`
	${fontRoboto}
	${bold14}
  padding: 2px 16px;
	border-radius: 8px;
	border: none;
	color: ${colors.white};
	background: linear-gradient(135deg, #1b998b 0%, #00ab98 100%);

	box-shadow: 0px 12px 24px 0px rgba(24, 156, 141, 0.24);

	> span {
		font-size: 14px;
		font-family: Roboto;
		font-style: normal;
		font-weight: 600;
		line-height: 20px;
	}

	&:not(:disabled):hover {
		color: ${colors.blackTr56} !important;
	}
`

export const RestyleButton = styled(Button)`
	${fontRoboto}
	${bold14}
  padding: 2px 16px;
	border-radius: 8px;
	border: none;
	color: ${colors.white};
	background: linear-gradient(135deg, #786aad 0%, #ab6cfc 100%);

	box-shadow: 0px 12px 24px 0px rgba(152, 106, 252, 0.24);

	> span {
		font-size: 14px;
		font-family: Roboto;
		font-style: normal;
		font-weight: 600;
		line-height: 20px;
	}

	&:not(:disabled):hover {
		color: ${colors.blackTr56} !important;
	}
`

export const IconWrapper = styled.div`
	padding: 5px;
	border-radius: 64px;
	display: inline-block;
	margin-right: 16px;
	background: var(--5, rgba(0, 0, 0, 0.05));
`

import { regular20, screenSize } from '@styles/index'
import {
	bold12,
	bold16,
	fontRoboto,
	fontTenorSans,
	regular13,
	regular16,
	regular18,
} from './../../styles/typography'
import { colors } from '@styles/colors'
import styled, { css } from 'styled-components'
import { Col } from 'antd'

export const DivCapsuleCard = styled.div`
	max-width: 468px;
	width: 100%;
	margin: 0 auto;
`

export const ImageCont = styled.div<{ imageUrl?: string }>`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 321px;
	border-radius: 20px;
	margin: 0 auto;
	box-shadow: 0px 6px 32px 0px ${colors.blackTr8};
	overflow: hidden;

	background-color: ${colors.white};
	background-position: top;
	background-repeat: no-repeat;
	background-size: contain;

	${({ imageUrl }) =>
		imageUrl &&
		css`
			background-image: url(${imageUrl});
		`}
`

export const Container = styled.div`
	position: relative;
	border-radius: 20px;
	cursor: pointer;
	height: 100%;

	:hover {
		& > div {
			box-shadow: 0px 6px 32px ${colors.blackTr8};
		}
	}

	&.empty {
		cursor: default;
		& > div {
			box-shadow: unset;
		}

		:hover {
			& > div {
				box-shadow: unset;
			}
		}
	}
`

export const DataWrapper = styled.div`
	background: ${colors.white};
	border-radius: 12px;
	margin: 0 16px;
	margin-top: -5px;
	box-shadow: 0px 6px 32px ${colors.blackTr8};
	position: relative;
	z-index: 100;

	@media (${screenSize.antXs}) {
		padding: 14px 16px;
	}
	@media (${screenSize.antSm}) {
		padding: 20px 24px;
	}
`

export const ClientDataWrapper = styled.div`
	border-radius: 12px;
	padding: 23px 16px;
	background: ${colors.white};
	margin: -20px 0 0;
	box-shadow: 0px 6px 32px ${colors.blackTr8};
`

export const WrapperFlex = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: start;
	margin-left: 16px;
	overflow: hidden;
`

export const Title = styled.div`
	${fontTenorSans}
	${regular20}
	color: ${colors.black};
	white-space: pre-line;
`

export const InfoBlock = styled.div`
	${fontRoboto}
	${regular20}
	color: ${colors.black};
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
`

export const Price = styled.div`
	${fontTenorSans}
	${regular18}
  color: ${colors.black};
`

export const Created = styled.div`
	${fontRoboto}
	${regular13}
  font-style: italic;
	vertical-align: bottom;
	color: ${colors.blackTr64};
	margin-right: 8px;
`

export const GrayLine = styled.div`
	display: block;
	width: 100%;
	height: 1px;
	background: ${colors.blackTr5};
	margin: 20px 0;
`

export const IconWrapper = styled.div`
	${fontRoboto}
	${bold16}
  color: ${colors.black};
	background: ${colors.blackTr5};
	padding: 12px;
	border-radius: 64px;
	line-height: 2rem;
	width: 44px;
	text-align: center;

	&:not(:first-child) {
		margin-left: 8px;
	}
`

export const Comment = styled.div`
	${fontRoboto}
	${regular16}
  color: ${colors.black};
	align-self: center;
	flex-grow: 1;
	margin-left: 20px;
`

export const DivStyled = styled.div`
	${fontRoboto}
	${regular13}
	
	color: ${colors.blackTr48};
	align-self: center;
	display: flex;
	flex: 1 1 auto;

	&:not(:first-child) {
		margin-left: 8px;
	}
`

export const CapsuleTitle = styled.div`
	${fontRoboto}
	${regular18}
	overflow: hidden;
	text-overflow: ellipsis;
`

export const StylistTitle = styled.div`
	${fontRoboto}
	${regular13}
	color: ${colors.blackTr64};
`
export const StylistInfoBlock = styled.span`
	${fontRoboto}
	${bold12}
	background: ${colors.black};
	color: ${colors.white};
	position: absolute;
	top: 16px;
	left: 16px;
	padding: 4px 16px;
	border-radius: 8px;
`

export const ArrowWrapper = styled(Col)`
	display: flex;
	justify-content: end;
	flex: 1 1 auto;
`

import { Col, Row } from 'antd'
import styled from 'styled-components'

import { colors } from '@styles/colors'
import { Button } from '@ui/button'
import {
	fontRoboto,
	bold16,
	bold14,
	fontTenorSans,
	regular18,
} from '@styles/typography'
import { Block } from '@ui/block'
import { screenSize } from '@styles/screen-sizes'

export const Container = styled.div`
	display: grid;
	grid-template-columns: 24% 33% 40%;
	grid-gap: 48px;
`

export const StylistInfoContainer = styled.div`
	display: block;
	width: 100%;
	margin: 0 auto;
	padding: 0 16px;
`

export const StylistInfoBlock = styled.div`
	position: relative;
	margin-top: -25%;
	padding: 20px 24px 24px;
	background: ${colors.white};
	box-shadow: 0px 6px 32px ${colors.blackTr8};
	border-radius: 12px;
`

export const StylistName = styled.div`
	${fontTenorSans}
	${regular18}
`

export const ButtonStyled = styled(Button)`
	${fontRoboto}
	${bold14}
	color: ${colors.blackTr64};
	margin: auto 12px;
	padding: 6px 10px;
`

export const ButtonMessenger = styled(Button)`
	& > div {
		margin-right: 0;
	}
`

export const BlockTitle = styled.div`
	${fontRoboto}
	${bold16}
	margin-bottom: 8px;
`

export const ColFlex = styled(Col)`
	display: flex;
`

export const RowBordered = styled(Row)`
	border: 2px solid ${colors.blackTr5};
	border-radius: 12px;

	width: 100%;
	margin: 0 0 40px;
`

export const BlockStyled  = styled(Block)`
	@media (${screenSize.antXs}) {
		padding: 0 0 24px;
	}
	@media (${screenSize.antSm}) {
		padding: 0;
	}
	@media (${screenSize.antLg}) {
		padding: 0 48px 48px;
	}
	@media only screen and (${screenSize.antLg}) {
		padding: 0;
	}
`

import styled from 'styled-components'

import EllipseWoman from '@assets/EllipseWoman.png'
import LoginBG from '@assets/loginBG.png'
import { colors } from '@styles/colors'
import { antWidth, screenSize } from '@styles/screen-sizes'
import {
	fontPlayfair,
	fontTenorSans,
	regular16,
	regular18,
	regular26,
	regular32,
	regular42,
} from '@styles/typography'
import { Block } from '@ui/block'
import { Button } from '@ui/button'

export const Container = styled.div`
	display: flex;
	height: 100%;
	width: inherit;

	@media (${screenSize.antXs}) {
		padding: 0;
		flex-direction: column-reverse;
		justify-content: start;
		gap: 24px;
	}
	@media (${screenSize.antSm}) {
		padding: 0;
		flex-direction: column-reverse;
		justify-content: start;
		gap: 24px;
	}	
	@media (${screenSize.antLg}) {
		width: 100%;
		padding: 0;
		flex-direction: row;
		gap: 40px;
		align-items: center;
	}
	@media (${screenSize.antXl}) {
		padding-left: ${antWidth.col1};
	}
	@media (${screenSize.antXxl}) {
		padding-left: ${antWidth.col2};
	}
`
export const BlockStyled  = styled(Block)`
	@media (${screenSize.antXs}) {

	}
	@media (${screenSize.antSm}) {

	}	
	@media (${screenSize.antLg}) {
		width: ${antWidth.col16};
	}
	@media (${screenSize.antXl}) {
		width: ${antWidth.col17};
	}
	@media (${screenSize.antXxl}) {
		width: ${antWidth.col12};
	}
`

export const BgCont = styled.div`
	background: url(${LoginBG});
	background-size: cover;
	background-repeat: no-repeat;
	background-position: bottom;
	position: absolute;
	opacity: 0.5;
	height: auto;
	top: 0;
	bottom: 0;
	pointer-events: none;

	@media (${screenSize.antXs}) {
		width: 100%;
		background-position: top;
	}
	@media (${screenSize.antSm}) {
		width: 100%;
		background-position: top;
	}
	@media (${screenSize.antLg}) {
		background-position: bottom;
	}
	@media (${screenSize.antXl}) {
		width: calc(100% - 108px);
	}
	
`

export const GapLineCol = styled.div`
	display: flex;
	width: 48px;
	margin: 136px auto;
	border-top: 2px solid ${colors.black};
`
export const TextBlockCol = styled.div`
	${fontPlayfair}

	@media (${screenSize.antXs}) {
		${regular26}
		text-align: center;
		margin: 24px auto;
		width: ${antWidth.col18};
	}
	@media (${screenSize.antSm}) {
		${regular26}
		text-align: center;
		margin: 24px auto;
		padding: 0 36px;
		width: ${antWidth.col19};
	}
	@media (${screenSize.antMd}) {
		${regular26}
		text-align: center;
	}
	@media (${screenSize.antLg}) {
		${regular26}
		text-align: left;
		margin: 0 0 70px;
		padding: 0;
	}
	@media (${screenSize.antXl}) {
		${regular32}
		text-align: left;
		margin: 0 0 24px;
	}
	@media (${screenSize.antXxl}) {
		margin: 0 0 70px;
		${regular42}
	}
`

export const InterviewMsg = styled.div`
	${fontTenorSans}
	${regular26}
	align-items: center;

	@media (${screenSize.antXs}) {
		${regular18}
		text-align: center;
		margin: 0 auto 24px;
	}
	@media (${screenSize.antSm}) {
		${regular18}
		text-align: center;
		margin: 0 auto 24px;
		padding: 0 36px;
	}
	@media (${screenSize.antLg}) {
		${regular18}
		text-align: left;
		margin: 0 0 48px;
		padding: 0;
	}
	@media (${screenSize.antXl}) {
		${regular26}
	}
`
export const Red = styled.i`
	color: red;
`

export const ImageBlock = styled.div`
	position: relative;
	background: url(${EllipseWoman});
	background-size: contain;
	background-repeat: no-repeat;
	width: 100%;
	max-height: 660px;
	margin: 0 auto;

	@media (${screenSize.antXs}) {
		height: 50%;
		max-width: ${antWidth.col24};
		background-position: center;
	}
	@media (${screenSize.antSm}) {
		height: 50%;
		max-width: ${antWidth.col24};
		background-position: left;
	}
	@media (${screenSize.antLg}) {
		height: 70%;
		max-width: ${antWidth.col13};
	}
	@media (${screenSize.antXl}) {
		height: 80%;
		max-width: ${antWidth.col16};
	}
`

export const NoteText = styled.span`
	height: 100%;
	${fontTenorSans}

	display: flex;
	flex-wrap: wrap;
	align-items: center;

	@media (${screenSize.antXs}) {
		${regular16}
		justify-content: center;
		text-align: center;
	}
	@media (${screenSize.antSm}) {
		${regular18}
		justify-content: center;
		text-align: center;
	}	
	@media (${screenSize.antLg}) {
		${regular18}
		justify-content: start;
		text-align: left;
	}
`

export const ButtonStyled = styled(Button)`
	@media (${screenSize.antXs}) {
		margin: 0 auto;
	}
	@media (${screenSize.antSm}) {
		margin: 0 auto;
	}
	@media (${screenSize.antLg}) {
		margin: unset;
	}
`

import { settingsSelector } from '@store/settings'
import { useAppSelector } from '@store/store'

export function useGetCurrency(id?: number) {
	const { settings } = useAppSelector(settingsSelector)
	const { Currencies } = settings

	if (!id) return ''

	if (!Currencies?.length) return '£'

	return Currencies?.find((currency) => currency.id === id)?.title
}

import { colors } from '@styles/colors'
import { screenSize } from '@styles/screen-sizes'
import { fontRoboto, fontTenorSans, regular16 } from '@styles/typography'
import { Block } from '@ui/block'
import styled, { css } from 'styled-components'

export const BlockStyled = styled(Block)`
	${regular16}
	${fontTenorSans}
  display: flex;
	flex-direction: column;
	height: 100%;
	border-radius: 8px;
	padding: 24px;
	justify-content: center;
	align-items: center;
`

export const Wrapper = styled.div<{ isActive?: boolean }>`
	cursor: pointer;
	border-radius: 8px;
	background: ${colors.white};
	height: 100%;

	${({ isActive }) =>
		isActive &&
		css`
			box-shadow: 0px 6px 32px 0px rgba(0, 0, 0, 0.06);
		`}

	&:hover {
		box-shadow: 0px 6px 32px 0px rgba(0, 0, 0, 0.06);
	}
`
export const Title = styled.div`
	${fontRoboto}
	font-size: 13px;
	font-style: normal;
	font-weight: 500;

	margin-top: 24px;
	text-transform: uppercase;
	color: ${colors.blackTr64};
	text-align: center;
`

export const IconWrapper = styled.div`
	height: 86px;

	@media (${screenSize.antLg}) {
		padding: 12px;
		height: 156px;
	}
`

import { Row } from 'antd'

import { Filters } from '@components/filters/filters'
import { PageNavigation } from '@components/page-navigation/page-navigation'
import { Block } from '@ui/block'
import { Spinner } from '@ui/spinner'
import StylistCard from './stylist-card/stylist-card'
import useStylistsPage from './use-stylists-page'

export default function StylistsPage() {
	const {
		token,
		navigationButtons,
		showListType,
		isLoading,
		recommendedStylists,
		handleChangeSearch,
		isFiltersOpen,
		setIsFiltersOpen,
	} = useStylistsPage()

	if (isLoading) {
		return <Spinner color="#FF5656" />
	}

	return (
		<Block isFullWidth margin={token ? '0 48px 48px' : '48px'}>
			{token && (
				<PageNavigation
					showListType={showListType}
					buttons={navigationButtons}
					handleChangeSearch={handleChangeSearch}
					onFilterClick={() => setIsFiltersOpen(true)}
					rightSideSlot={<></>}
				/>
			)}
			<Row
				gutter={{ md: 20, xxl: 48 }}
				justify={{
					xs: 'center',
					md: 'center',
					lg: 'center',
					xl: 'center',
					xxl: 'start',
				}}
				style={{ rowGap: '32px' }}
			>
				{recommendedStylists.map((stylist, index) => (
					<StylistCard key={index} stylist={stylist} />
				))}
			</Row>
			<Filters
				isOpen={isFiltersOpen}
				onClose={() => setIsFiltersOpen(false)}
				onSearch={() => setIsFiltersOpen(false)}
			/>
		</Block>
	)
}

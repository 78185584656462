import { Block, Icon, Input } from '@ui/index'
import { CustomSelect } from '@ui/select/select'
import { Col, Row } from 'antd'
import dayjs from 'dayjs'
import {
	ContentBlock,
	FormWrapper,
	TitleBlock,
} from './personal-data-form-styled'
import { usePersonalDataForm } from './use-personal-data-form'
import { DatePickerSD } from '@ui/date-picker'

export function PersonalDataForm() {
	const {
		cities,
		client,
		countries,
		genderOptions,
		handleCountrySelect,
		handleSetUserInfo,
		handleSetUserParams,
		handleSetUserPhone,
		isCityDisabled,
		isCountriesLoading,
		isSettingsLoading,
		register,
		setValue,
		watch,
		userInfo,
	} = usePersonalDataForm()

	return (
		<Col xs ={{span: 24 }} md={{}} xl={{span: 8, offset: 0}}>
			<FormWrapper>
				<TitleBlock>Personal details</TitleBlock>
				<ContentBlock>
					<Row
						gutter={[8, 36]}
						style={{ width: '100%' }}
						justify="space-between"
					>
						<Col xs={{span: 24}} >
							<Input
								label="firstName"
								inputFieldSlot={
									<input
										{...register('firstName')}
										onBlur={(e: any) => {
											if (e.target.value === '') {
												handleSetUserInfo('first_name', client.first_name)
												setValue('firstName', client.first_name)
												return
											}

											handleSetUserInfo('first_name', e.target.value)
										}}
										type="text"
										defaultValue={client.first_name}
									/>
								}
								errorText={'errorText'} //errors.firstName && errors.firstName.message
								width="100%"
								placeholder="Name"
								hasValue={!!watch('firstName')}
							/>
						</Col>
						<Col xs={{span: 24}} >
							<Input
								label="lastName"
								inputFieldSlot={
									<input
										{...register('lastName')}
										onBlur={(e: any) => {
											handleSetUserInfo('last_name', e.target.value)
										}}
										type="text"
										defaultValue={client.last_name}
									/>
								}
								errorText={'errorText'} //{errors.lastName && errors.lastName.message}
								width="100%"
								placeholder="Surname"
								hasValue={!!watch('lastName')}
							/>
						</Col>
						<Col xs={{span: 24}} sm={{span: 12}}>
							<CustomSelect
								isDisabled={isSettingsLoading}
								placeholder="Gender"
								defaultValue={client.sex}
								width={'100%'}
								onChange={(value: string) => handleSetUserInfo('sex', value)}
								options={genderOptions}
							/>
						</Col>
						<Col xs={{span: 24}} sm={{span: 12}}>
							<DatePickerSD
								placeholder="Date of birth"
								defaultValue={client.params?.birthday?.[0]}
								onChange={(dateString) =>
									dateString &&
									handleSetUserParams('birthday', dateString.toString())
								}
								suffixIcon={<Icon name="calendarIcon" />}
							/>
						</Col>
						<Col xs={{span: 24}} sm={{span: 12}}>
							<CustomSelect
								isDisabled={isCountriesLoading}
								placeholder="Country"
								defaultValue={client.params?.country}
								searchEnabled
								width={'100%'}
								onChange={handleCountrySelect}
								options={countries}
							/>
						</Col>
						<Col xs={{span: 24}} sm={{span: 12}}>
							<CustomSelect
								placeholder="City"
								defaultValue={client.params?.city}
								searchEnabled
								width={'100%'}
								onChange={(value: string) => handleSetUserParams('city', value)}
								isDisabled={isCountriesLoading || isCityDisabled}
								options={cities}
							/>
						</Col>
						<Col xs={{span: 24}} sm={{span: 12}}>
							<Input
								label="Client height"
								inputFieldSlot={
									<input
										{...register('clientHeight')}
										onBlur={(e: any) => {
											handleSetUserParams('clientHeight', e.target.value)
										}}
										type="text"
										defaultValue={client.params?.clientHeight}
									/>
								}
								isError={!client.params?.clientHeight}
								placeholder="Height"
								hasValue={!!watch('clientHeight')}
							/>
						</Col>
						<Col xs={{span: 24}} sm={{span: 12}}>
							<Input
								label="phone"
								inputFieldSlot={
									<input
										{...register('phone')}
										onBlur={(e: any) => {
											handleSetUserPhone(e.target.value)
										}}
										type="text"
										defaultValue={userInfo.phone}
									/>
								}
								width="100%"
								isError={!userInfo.phone}
								placeholder="Phone number"
								hasValue={!!watch('phone')}
							/>
						</Col>
						<Col xs={{span: 24}} >
							<Input
								label="email"
								inputFieldSlot={
									<input
										{...register('email')}
										onBlur={(e: any) => {
											handleSetUserInfo('email', e.target.value)
										}}
										type="text"
										defaultValue={userInfo.email}
										disabled
									/>
								}
								isError={!userInfo.email}
								width="100%"
								placeholder="Email"
								hasValue={true}
							/>
						</Col>
					</Row>
				</ContentBlock>
			</FormWrapper>
		</Col>
	)
}

import { Col, Input } from 'antd'
import styled, { css } from 'styled-components'

import { colors } from '@styles/colors'
import { screenSize } from '@styles/screen-sizes'
import { bold14, fontRoboto } from '@styles/typography'
import { Button } from '@ui/button'

const { Search } = Input

export const ColBordered = styled(Col)<{ isHideMobileBorder?: boolean }>`
	border-radius: 12px;

	margin: 0 0 40px;
	min-height: 66px;
	width: 100%;
	padding: 0 !important;

	& > div:first-child {
		padding-left: 0 !important;
	}

	border: 2px solid ${colors.blackTr5};

	${({ isHideMobileBorder }) =>
		isHideMobileBorder &&
		css`
			border: none;

			@media (${screenSize.antXs}) {
				border: none;
			}

			@media (${screenSize.antMd}) {
				border: none;
			}

			@media (${screenSize.antLg}) {
				border: 2px solid ${colors.blackTr5};
			}
		`}
`

export const ColRightAlign = styled(Col)`
	display: flex;
	justify-content: end;
`

export const ButtonAll = styled(Button)`
	${fontRoboto}
	${bold14}
	border-radius: 5px 0 0 5px;
	border: none;
	padding: 22px 18px;
	max-height: 66px;

	@media (${screenSize.antXs}) {
		width: 50%;
	}
	@media (${screenSize.antMd}) {
		width: 50%;
	}
	@media (${screenSize.antLg}) {
		width: unset;
	}
`

export const ButtonDraft = styled(Button)`
	${fontRoboto}
	${bold14}
	width: auto;
	border-radius: 0;
	border: none;
	border-left: 1px solid rgba(0, 0, 0, 0.05);
	border-right: 1px solid rgba(0, 0, 0, 0.05);
	max-height: 66px;
	text-align: start;
	color: ${(p) => (p.color ? p.color : 'unset')};

	&:not:first-child {
		border-left: none;
	}

	@media (${screenSize.antXs}) {
		border-radius: 0;
		padding: 12px;
	}
	@media (${screenSize.antSm}) {
		border-radius: 0 5px 5px 0;
		padding: 22px 18px;
	}
	@media (${screenSize.antMd}) {
		border-radius: 0 5px 5px 0;
	}
	@media (${screenSize.antLg}) {
		width: unset;
		border-radius: 0;
	}
`

export const SearchStyled = styled(Search)`
	${fontRoboto}
	${bold14}
	flex: 1 1 auto;

	& .ant-input-affix-wrapper {
		padding: 8px 12px;
	}

	& .ant-input-search-button {
		height: 40px;
	}
`

export const MobileContainer = styled.div`
	margin: -20px 0 20px;

	@media (${screenSize.antXs}) {
		display: block;
	}
	@media (${screenSize.antSm}) {
		display: block;
	}
	@media (${screenSize.antMd}) {
		display: block;
	}
	@media (${screenSize.antLg}) {
		display: none;
	}
`

export const DesktopContainer = styled.div`
	margin: 11px;
	width: 100%;

	@media (${screenSize.antXs}) {
		display: none;
	}
	@media (${screenSize.antSm}) {
		display: none;
	}
	@media (${screenSize.antMd}) {
		display: none;
	}
	@media (${screenSize.antLg}) {
		display: block;
	}
`

export const ButtonFilter = styled(Button)`
	margin: 11px;
	padding: 0 6px;
	max-height: 40px;

	& > div {
		margin-right: 0;
	}
`

export const IconWrapper = styled.div`
	width: 21px;
	height: 21px;

	display: flex;
	justify-content: center;
	align-items: center;

	border-radius: 16px;
	background: var(---, linear-gradient(180deg, #ff0c63 0%, #ff5656 100%));
	backdrop-filter: blur(2px);
`

import { IconBorder } from '@components/quiz/quiz-styled'
import { Icon } from '@ui/icon'
import { ReactNode } from 'react'
import {
	BlockStyled,
	IconWrapper,
	Title,
	Wrapper,
} from './new-user-button-styled'

type Props = {
	icon?: ReactNode
	onClick: () => void
	title: string
	isActive: boolean
}

export function NewUserButton({ onClick, title, icon, isActive }: Props) {
	return (
		<Wrapper isActive={isActive} onClick={onClick}>
			<BlockStyled>
				<IconWrapper>{icon}</IconWrapper>
				<IconBorder style={{ marginTop: 24 }}>
					{isActive && <Icon name="checkCircleColoredIcon" color="#FF0C63" />}
				</IconBorder>
				<Title>{title}</Title>
			</BlockStyled>
		</Wrapper>
	)
}

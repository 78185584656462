import { colors } from '@styles/colors'
import { fontRoboto, regular14, regular16, screenSize } from '@styles/index'
import { Row } from 'antd'
import styled from 'styled-components'

export const SelectWrapperRow = styled(Row)`
	align-items: center;
	margin-top: 36px;

	.styledyle-input-paddings {
		${fontRoboto}
		color: ${colors.blackTr64};
		width: 48%;

		@media (${screenSize.antXs}) {
			${regular14}
			height: 42px;
			padding: 12px;
		}
		@media (${screenSize.antSm}) {
			${regular14}
			height: 42px;
			padding: 12px;
		}
		@media (${screenSize.antLg}) {
			${regular16}
			height: 56px;
			padding: 18px 16px;
		}
	}

	.ant-picker .ant-picker-input > input {
		font-size: unset;
		line-height: unset;
		color: unset;
		color: ${colors.black};
	}
	.ant-picker .ant-picker-input > input::placeholder {
		color: unset !important;
	}
`

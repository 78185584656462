import { Navigate, Outlet, useLocation } from 'react-router-dom'

import { useLoginPage } from '@pages/login/use-login-page'
import { Spinner } from '@ui/spinner'

export function AuthRequired() {
	const { isLoading, token } = useLoginPage()
	const { pathname } = useLocation()

	if (isLoading && !token.length) return <Spinner color="#FF0C63" />
	if (!isLoading && !token.length && pathname === '/')
		return <Navigate to="/login" />

	return <Outlet />
}

import { Col, Row } from 'antd'
import { useMediaQuery } from 'react-responsive'

import { ItemsList } from '@components/items-list/items-list'
import { PageNavigation } from '@components/page-navigation/page-navigation'
import { StyleBoardsItem } from '@components/styleboard-item'
import { Block, ButtonLinkTo, Icon, Skeleton } from '@ui/index'
import { useStyleboardsPage } from './use-styleboards-page'

const STYLEBOARD_SIZE = {
	width: 393,
	height: 493,
}

export function StyleBoardsPage() {
	const {
		handleAddNewStyleboard,
		handleChangeSearch,
		handleStyleboardClick,
		isLoading,
		showListType,
		styleboardsList,
		navigationButtons,
	} = useStyleboardsPage()

	const isXxl = useMediaQuery({
		query: '(min-width: 1600px)',
	})
	const itemsPerPage = isXxl ? 4 : 3
	const loadersArr = new Array(itemsPerPage * 2 - 1).fill(1)

	return (
		<Block isFullWidth margin="0 48px 48px">
			<PageNavigation
				showListType={showListType}
				buttons={navigationButtons}
				handleChangeSearch={handleChangeSearch}
				rightSideSlot={false}
			/>
			<Row gutter={[48, 28]}>
				<Col xxl={{ span: 6 }} lg={{ span: 8 }} sm={{ span: 12, offset: 0 }} xs={{ span: 22, offset: 1 }}>
					<ButtonLinkTo
						onClick={handleAddNewStyleboard}
						minHeight="474px"
						maxWidth={STYLEBOARD_SIZE.width + 'px'}
						title="Add Styleboard"
						icon={<Icon name="styleBoardAddIcon" size={72} />}
					/>
				</Col>
				<ItemsList
					isLoading={isLoading}
					itemsList={styleboardsList}
					loaderEl={
						<>
							{loadersArr.map((_, index) => (
								<Col key={index} xxl={{ span: 6 }} lg={{ span: 8 }} sm={{ span: 12, offset: 0 }} xs={{ span: 22, offset: 1 }}>
									<Skeleton width="100%" height={STYLEBOARD_SIZE.height}>
										<rect
											x="0"
											y="0"
											rx="20"
											ry="20"
											width="100%"
											height={STYLEBOARD_SIZE.height}
										/>
									</Skeleton>
								</Col>
							))}
						</>
					}
					renderItem={(item) => (
						<Col key={item.id} xxl={{ span: 6 }} lg={{ span: 8 }} sm={{ span: 12, offset: 0 }} xs={{ span: 22, offset: 1 }}>
							<StyleBoardsItem
								styleBoard={item}
								onClick={handleStyleboardClick}
								maxWidth={STYLEBOARD_SIZE.width + 'px'}
							/>
						</Col>
					)}
				/>
			</Row>
		</Block>
	)
}

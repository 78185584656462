import styled from 'styled-components'

import LoginBG from '@assets/loginBG.png'
import { colors } from '@styles/colors'
import { Block } from '@ui/block'

export const PageWrapper = styled(Block)`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: url(${LoginBG}),
		linear-gradient(0deg, rgba(197, 189, 252, 0.12), rgba(197, 189, 252, 0.12)),
		#ffffff;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: bottom;
	height: 100%;

	padding: 0 19px;
`

export const ContentBlockWrapper = styled.div`
	display: flex;
	flex-flow: column;
	justify-content: center;

	width: 100%;
	max-width: 346px;
	padding: 32px 25px;
	margin: 0 16px;

	background: ${colors.bgColor};
	box-shadow: 0px 6px 32px ${colors.blackTr8};
	border-radius: 20px;

	a {
		color: ${colors.black};
		text-decoration: none;
	}

	color: ${colors.black};
`

import { useCallback, useEffect, useMemo, useState } from 'react'

import { useGetCurrency } from '@hooks/use-get-currency'
import { authSelector } from '@store/auth'
import { setItem } from '@store/clothes'
import { useAppDispatch, useAppSelector } from '@store/store'
import { PROD_DOMAIN } from '@typings/constants'
import { ImageItemType, ItemInfo, NavigationButtonInfo } from '@typings/types'
import { useLocation } from 'react-router-dom'

export function useClothesPreviewPage() {
	const { state } = useLocation()
	const dispatch = useAppDispatch()
	const [imageItems, setImageItems] = useState<ImageItemType[]>([])
	const { token } = useAppSelector(authSelector)

	const item: ItemInfo = state?.item ?? undefined

	const loadItemInfo = useCallback(async () => {
		if (item) {
			const images = item.images || []
			const imageItems = images.map((image) => ({
				id: image.id,
				url: PROD_DOMAIN + image.urls.M,
				previewUrl: PROD_DOMAIN + image.urls.L,
				isCover: item.coverPhoto?.id === image.id,
			}))
			setImageItems(imageItems)
			dispatch(
				setItem({
					...item,
				})
			)
			return
		}
	}, [item])

	useEffect(() => {
		if (!item) return
		loadItemInfo()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const currency = useGetCurrency(item?.price_currency_id)

	const navigationButtons: NavigationButtonInfo[] = useMemo(
		() => [
			{
				title: 'Preview',
				iconName: 'previewIcon',
				showListType: 'preview',
				handleClick: () => {},
			},
		],
		[]
	)

	const handleClothLinkSelect = (event: React.MouseEvent<HTMLElement>) => {
		const element = event.currentTarget as HTMLInputElement
		element.select()

		return element.value
	}

	const handleLinkClick = (event: React.MouseEvent<HTMLElement>) => {
		if (!item.source_url) return

		window.open(item.source_url, '_blank')
	}

	return {
		token,
		item,
		currency,
		imageItems,
		navigationButtons,
		handleClothLinkSelect,
		handleLinkClick,
	}
}

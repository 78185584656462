import { colors } from '@styles/colors'
import { fontRoboto, regular13 } from '@styles/typography'
import styled from 'styled-components'

export const CookieBlock = styled.div`
	${fontRoboto}
	${regular13}
	color: ${colors.blackTr64};

	& a {
		color: ${colors.mainThemeColor};

		&:hover {
			text-decoration: underline;
		}
	}
`

export const AStyled = styled.a`
	${fontRoboto}
	${regular13}
	color: ${colors.mainThemeColor} !important;
	padding-left: 24px;

	&:hover {
		text-decoration: underline;
	}
`

export const SubscribeCheckBox = styled.div`
	${fontRoboto}
	${regular13}
	color: ${colors.blackTr64};
	font-style: italic;

	margin-top: 15px;

	& span {
		${fontRoboto}
		${regular13}
		color: ${colors.blackTr64};
	}

	.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
		.ant-checkbox-checked:not(.ant-checkbox-disabled)
		.ant-checkbox-inner {
		background-color: rgb(255, 12, 99);
	}

	.ant-checkbox-wrapper {
		&:hover {
			.ant-checkbox-inner {
				background-color: rgb(255, 12, 99);
				border-color: rgb(255, 12, 99);
			}
		}

		.ant-checkbox {
			align-self: flex-start;
			margin-top: 5px;

			.ant-checkbox-inner {
				background-color: #fff;
				border-color: rgb(255, 12, 99);
			}
		}

		.ant-checkbox-checked {
			.ant-checkbox-inner {
				background-color: rgb(255, 12, 99);
				border-color: rgb(255, 12, 99);
			}
		}

		:where(.css-dev-only-do-not-override-1xg9z9n).ant-checkbox-wrapper:not(
				.ant-checkbox-wrapper-disabled
			):hover
			.ant-checkbox-checked:not(.ant-checkbox-disabled)
			.ant-checkbox-inner {
			background-color: rgb(255, 12, 99);
			border-color: transparent;
		}
	}
`

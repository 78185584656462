import styled, { css } from 'styled-components'
import { colors } from './../../../styles/colors'
import { screenSize } from '@styles/index'

const ICON_GAP = 13

export const Container = styled.div`
	width: 100%;
	position: relative;

	@media (${screenSize.antXs}) {
		height: 48px;
	}
	@media (${screenSize.antSm}) {
		height: 48px;
	}
	@media (${screenSize.antLg}) {
		height: 56px;
	}
`

export const Line = styled.div`
	width: 100%;
	position: absolute;
	left: 0;
	border-top: 1px solid ${colors.blackTr8};

	@media (${screenSize.antXs}) {
		top: 24px;
	}
	@media (${screenSize.antSm}) {
		top: 24px;
	}
	@media (${screenSize.antLg}) {
		top: 28px;
	}
`
export const IconsCont = styled.div`
	display: flex;
	flex-flow: row;
	flex-wrap: nowrap;
	width: 100%;

	position: relative;
`

export const IconWrapper = styled.div<{ step: number; quizLength: number }>`
	position: absolute;
	top: 0;
	display: flex;
	transition: all 0.5s ease-in-out;

	@media (${screenSize.antXs}) {
		${(p) =>
			css`
				left: calc(95% - ${ICON_GAP * (p.quizLength - p.step)}px);
			`};
		&.done {
			${(p) =>
				css`
					left: calc(${ICON_GAP * (p.step - 1)}px);
				`};
		}

		&.active {
			left: 48%;
		}
	}

	@media (${screenSize.antSm}) {
		${(p) =>
			css`
				left: calc(97% - ${ICON_GAP * (p.quizLength - p.step)}px);
			`};
		&.done {
			${(p) =>
				css`
					left: calc(${ICON_GAP * (p.step - 1)}px);
				`};
		}

		&.active {
			left: 48%;
		}
	}
`

export const IconCont = styled.div`
	z-index: 100;

	@media (${screenSize.antXs}) {
		padding: 10px 0;
	}
	@media (${screenSize.antSm}) {
		padding: 10px 0;
	}
	@media (${screenSize.antLg}) {
		padding: 14px 0;
	}
`

export const size = {
	minMobileSmall: '360px',
	minMobile: '480px',
	minTablet: '768px',
	minLaptop: '1024px',
	minDesktop: '1200px',
	maxMobileSmall: '479px',
	maxMobile: '767px',
	maxTablet: '1024px',
	maxLaptop: '1199px',
	antSm: '576px',
	antMd: '768px',
	antLg: '991px',
	antXl: '1199px',
	antXXl: '1600px',
}

export const sizeNum = {
	antSm: 576,
	antMd: 768,
	antLg: 991,
	antXl: 1199,
	antXXl: 1600,
}

export const antWidth = {
	col1: '4.166666666666666%',
	col2: '8.333333333333332%',
	col3: '12.5%',
	col4: '16.666666666666664%',
	col5: '20.833333333333336%',
	col6: '25%',
	col7: '29.166666666666668%',
	col8: '33.33333333333333%',
	col9: '37.5%',
	col10: '41.66666666666667%',
	col11: '45.83333333333333%',
	col12: '50%',
	col13: '54.166666666666664%',
	col14: '58.333333333333336%',
	col15: '62.5%',
	col16: '66.66666666666666%',
	col17: '70.83333333333334%',
	col18: '75%',
	col19: '79.16666666666666%',
	col20: '83.33333333333334%',
	col21: '87.5%',
	col22: '91.66666666666666%',
	col23: '95.83333333333334%',
	col24: '100%',

}

export const screenSize = {
	minMobileSmall: `min-width: ${size.minMobileSmall}`,
	minMobile: `min-width: ${size.minMobile}`,
	minTablet: `min-width: ${size.minTablet}`,
	minLaptop: `min-width: ${size.minLaptop}`,
	minDesktop: `min-width: ${size.minDesktop}`,
	maxMobileSmall: `max-width: ${size.maxMobileSmall}`,
	maxMobile: `max-width: ${size.maxMobile}`,
	maxTablet: `max-width: ${size.maxTablet}`,
	maxLaptop: `max-width: ${size.maxLaptop}`,
	antXs: `(max-width:  ${size.antSm})`,
	antSm: `(min-width: ${size.antSm})`,
	antMd: `(min-width: ${size.antMd})`,
	antLg: `(min-width: ${size.antLg})`,
	antXl: `(min-width: ${size.antXl})`,
	antXxl: `(min-width: ${size.antXXl})`,
}

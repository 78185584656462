import styled from 'styled-components';

import {
	fontPlayfair,
	fontRoboto,
	fontTenorSans,
	regular16,
	regular18,
	regular26,
	regular32,
	regular42,
} from '@styles/typography'
import { Button } from '@ui/index'
import { antWidth, colors, screenSize } from '@styles/index'


export const MainText = styled.div`
	${fontTenorSans}
	${regular26}

	align-items: center;
	text-align: center;

	color: ${colors.blackTr64};

	@media (${screenSize.antXs}) {
		width: ${antWidth.col20};
		${regular18}
		margin: 24px auto 0;
	}
	@media (${screenSize.antSm}) {
		width: ${antWidth.col20};
		${regular18}
		margin: 24px auto 0;
	}
	@media (${screenSize.antMd}) {
		width: ${antWidth.col13};
		${regular26}
		margin: 48px auto 0;
	}

	@media (${screenSize.antLg}) {
		width: ${antWidth.col10};
		${regular26}
		letter-spacing: 0.1px;
	}

	@media (${screenSize.antXl}) {
		width: ${antWidth.col8};

	}
`
export const ButtonStyled = styled(Button)`
	margin: 88px auto 0;
`

export const TimeText = styled.div`
	${fontRoboto}
	${regular16}
	
	margin: 21px auto 0;
	align-items: center;
	text-align: center;

	color: ${colors.blackTr64};
`

export const HeaderText = styled.h1`
	${fontPlayfair}
	${regular42}
	
	align-items: center;
	text-align: center;

	color: ${colors.black};
	letter-spacing: 0.84px;

	@media (${screenSize.antXs}) {
		margin: 32px auto 0;
		width: ${antWidth.col20};
		${regular26}
	}

	@media (${screenSize.antSm}) {
		margin: 40px auto 0;
		width: ${antWidth.col20};
		${regular26}
	}

	@media (${screenSize.antMd}) {
		margin: 20px auto 0;
		width: ${antWidth.col12};
		${regular32}
	}

	@media (${screenSize.antLg}) {
		margin: 80px auto 0;
		width: ${antWidth.col9};
		${regular42}
	}

	@media (${screenSize.antXl}) {
		margin: 144px auto 0;
		width: ${antWidth.col9};
	}
`

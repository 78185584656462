import { useEffect } from 'react'

export const useOutsideAlerter = (ref: any, onClickOutside: () => void) => {
	useEffect(() => {
		function handleClickOutside(event: any) {
			if (ref.current && !ref.current.contains(event.target)) {
				console.log('You clicked outside of me!')
				onClickOutside()
			}
		}
		// Bind the event listener
		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [ref])
}

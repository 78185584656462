import { SdNotification } from '@components/sd-notification'
import { Col, Flex, Row } from 'antd'
import { useNavigate } from 'react-router-dom'
import { ButtonBack2Quiz, RowBordered } from './client-profile-form-styled'
import { PersonalDataForm } from './personal-data-form/personal-data-form'
import { PreferencesDataForm } from './preferences-data-form/preferences-data-form'
import { UploadAvatar } from './upload-avatar/upload-avatar'
import { useResize } from '@hooks/use-resize'

export function ClientProfileForm() {
	const navigate = useNavigate()
	const screenSize = useResize()

	return (
		<Row gutter={48} align="top" justify={{xs: 'center', lg: 'start', xl: 'center'}} >
			<Col xs={{span: 24}} sm={{}} md={{}} lg={{}} xl={{span: 6}}>
				<Flex gap="middle" align='center' vertical={screenSize.isScreenXl || screenSize.isScreenXs} justify='center'>
					<UploadAvatar />
					<ButtonBack2Quiz
						skin="white-outline"
						onClick={() => navigate('/quiz')}
						isFitContent={screenSize.isScreenXl}
						style={{ marginTop: screenSize.isScreenXl ? 24 : 0 }}
					>
						Back to quiz
					</ButtonBack2Quiz>
				</Flex>
			</Col>
			<Col xs={{span: 24}} sm={{}} md={{}} lg={{span: 20, offset: 4}} xl={{span: 18, offset: 0}}>
				<RowBordered gutter={2}>
					<Col xs={{span: 24}} sm={{}} md={{}} lg={{}} xl={{span: 18, offset: 0}}>
						<SdNotification text="We autosave every change you make" />
					</Col>
				</RowBordered>
				<Row gutter={48} style={{ marginTop: 42 }}>
					<PersonalDataForm />
					<PreferencesDataForm />
				</Row>
			</Col>
		</Row>
	)
}

import { screenSize } from '@styles/screen-sizes'
import styled from 'styled-components'

export const Wrapper = styled.div`
	display: flex;
	flex-grow: 1;
	flex-direction: row;
	width: 100%;
	background-color: #ffffff;
`

export const ChildrenWrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-grow: 1;

	@media (${screenSize.antLg}) {
		padding: 0 24px 24px;
	}
	@media (${screenSize.antXl}) {
		padding: 0 48px 48px;
	}
`

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	width: calc(100% - 108px);
	margin-left: 108px;
`

import { Col } from 'antd'
import styled, { css } from 'styled-components'

import { colors } from '@styles/colors'
import { screenSize } from '@styles/screen-sizes'
import { fontRoboto, regular16 } from '@styles/typography'
import { Block } from '@ui/index'

export const ProfileInfoWrapper = styled(Col)`
	margin-right: 50px;

	display: flex;
	flex-direction: row;
	justify-content: end;
	align-items: center;
	gap: 40px;

	text-align: center;

	@media (${screenSize.maxLaptop}) {
		gap: 20px;
		margin-right: 20px;
	}
`

export const UserName = styled.div`
	font-size: 18px;
	line-height: 24px;

	display: flex;
	justify-content: end;
	letter-spacing: 0.005em;
	color: ${colors.black};
`

export const UserRole = styled.div`
	${fontRoboto}
	font-size: 13px;
	line-height: 18px;

	display: flex;
	justify-content: end;
	align-items: center;
	letter-spacing: 0.01em;

	color: ${colors.blackTr48};
`

export const ProfileWrapper = styled.div<{ imageUrl?: string }>`
	display: flex;
	justify-content: center;
	align-items: center;

	width: 48px;
	height: 48px;
	padding: 8px;
	margin-left: 12px;
	border-radius: 50%;
	background: rgba(0, 0, 0, 0.12);
	box-shadow: 0px 0px 0px 8px ${colors.blackTr8};
	cursor: pointer;

	${({ imageUrl }) =>
		imageUrl &&
		css`
			background-image: url(${imageUrl});
		`}
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
`

export const ProfileArrowBlock = styled(Block)`
	position: relative;
	cursor: pointer;
`

export const DropDownProfileBlock = styled(Block)`
	position: absolute;
	top: 35px;
	right: 0;

	${regular16}
	border: 1px solid #000;
	border-radius: 2px;

	padding: 10px;
	background-color: #fff;
	width: 88px;

	transition: all ease-in 0.2s;

	&:hover {
		background: #eee;
	}
`

export const ClickedBlock = styled(Block)`
	cursor: pointer;
`

import styled from 'styled-components'

import { colors } from '@styles/colors'
import { fontRoboto, fontTenorSans, regular10 } from '@styles/typography'

export const CircleWrapper = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	min-width: 32px;
	height: 32px;
	border-radius: 50%;
`

export const TextWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;

	margin-left: 16px;

	div:nth-child(1) {
		${fontTenorSans}
		font-weight: 400;
		font-size: 18px;
		line-height: 24px;

		color: ${colors.black};
	}

	div:nth-child(2) {
		${fontRoboto}
		font-weight: 400;
		font-size: 13px;
		line-height: 18px;

		color: ${colors.blackTr48};
	}
`

export const Badge = styled.div`
	position: absolute;
	bottom: -5px;
	right: -5px;

	display: flex;
	justify-content: center;
	align-items: center;

	width: 21px;
	height: 21px;

	background: ${colors.mainThemeColor};
	border-radius: 50%;

	${fontTenorSans}
	${regular10}

	color: ${colors.white};
`

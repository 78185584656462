import { DealStatus } from '@typings/types'
import { Icon } from '@ui/icon'
import { useMemo } from 'react'
import {
	AcceptButton,
	Container,
	IconWrapper,
	RestyleButton,
} from './review-buttons-styled'

type Props = {
	status?: DealStatus
	onAcceptClick: () => void
	onRestyleClick: () => void
}

export function ReviewButtons({
	status,
	onAcceptClick,
	onRestyleClick,
}: Props) {
	const acceptBtnObj = useMemo(
		() =>
			status?.clientActions?.find(
				(action) => action.action === 'styleboard_accept'
			),
		[status]
	)

	const restyleBtnObj = useMemo(
		() =>
			status?.clientActions?.find(
				(action) => action.action === 'styleboard_restyle'
			),
		[status]
	)

	return (
		<Container>
			{!!status?.clientActions.length &&
				(acceptBtnObj?.enabled || restyleBtnObj?.enabled) && (
					<div>
						<IconWrapper>
							<Icon name="heartIcon" size={28} color="#000000A3" />{' '}
						</IconWrapper>
						<span>
							<span>Heart</span>&nbsp; the styles you like and cross those you
							don't, then click on a button
						</span>
					</div>
				)}
			{!!acceptBtnObj && (
				<AcceptButton disabled={!acceptBtnObj.enabled} onClick={onAcceptClick}>
					{acceptBtnObj.button_text}
				</AcceptButton>
			)}
			{!!restyleBtnObj && (
				<RestyleButton
					disabled={!restyleBtnObj.enabled}
					onClick={onRestyleClick}
				>
					{restyleBtnObj.button_text}
				</RestyleButton>
			)}
		</Container>
	)
}

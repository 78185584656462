import { ClientProfileForm } from '@components/client-profile-form/client-profile-form'
import { Block } from '@ui/index'

export function ClientProfilePage() {
	return (
		<Block isFullWidth>
			<ClientProfileForm />
		</Block>
	)
}

import { Col, Row } from 'antd'
import ContentLoader from 'react-content-loader'

import { PageNavigation } from '@components/page-navigation/page-navigation'
import { ClientCapsulesBlock } from '@components/client-capsules/client-capsules'
import { ClientStyleBoardPreview } from '@components/client-styleboard-preview/client-styleboard-preview'
import { StylistInfoBlock } from '@components/stylist-info-block'
import { Block, Spinner, Icon, Swap, Modal } from '@ui/index'
import { colors } from '@styles/colors'
import { useStylingStudio } from './use-styling-studio'
import { BlockStyled, ButtonStyled, RowBordered } from './styling-stydio-styled'

export default function StylingStudio() {
	const {
		activeStylist,
		buttons,
		isLoading,
		isDealDetailsLoading,
		isModalOpen,
		handleCancel,
		handleOk,
		showListType,
		userRole,
		dealInfo,
		handleActionApply,
		navigate,
		screenSize
	} = useStylingStudio()

	const PageNavigationStylistsLoader = () => (
		<RowBordered gutter={0}>
			{[1, 2, 3].map((el) => (
				<ContentLoader
					key={el}
					speed={2}
					width={160}
					height={66}
					viewBox="0 0 160 66"
					backgroundColor={colors.blackTr12}
					foregroundColor="#ecebeb"
				>
					<circle cx="38" cy="33" r="20" />
					<rect x="70" y="26" rx="3" ry="3" width="70" height="14" />
					<rect x="159" y="0" width="1" height="66" />
				</ContentLoader>
			))}
		</RowBordered>
	)

	return (
		<>
			<BlockStyled isFullWidth>
				<Swap is={isLoading} isSlot={<PageNavigationStylistsLoader />}>
					<PageNavigation
						isShowAllBtn={false}
						showListType={showListType}
						buttons={buttons}
						rightSideSlot={
							<>
								{userRole === 'client' && !isLoading && (
									<ButtonStyled
										skin={'grey-outline'}
										isFitContent
										leftIcon={
											<Icon
												name="plusCircle"
												color={colors.blackTr64}
												size={20}
											/>
										}
										onClick={() => {
											navigate('/stylists')
										}}
									>
										{screenSize.isScreenXl ? 'Book new stylist' : ''}
									</ButtonStyled>
								)}
							</>
						}
					/>
				</Swap>
				<Row gutter={[24, 48]}>
					<Swap is={isDealDetailsLoading} isSlot={<Spinner />}>
						<Col xs={{span: 24}} md={{span: 14, offset: 5}} xl={{ span: 8, offset: 0 }} xxl={{ span: 7 }}  >
							<StylistInfoBlock
								dealInfo={dealInfo}
								onActionApply={handleActionApply}
							/>
						</Col>
						<Col xs={{span: 24}} md={{span: 12}} xl={{ span: 8 }} xxl={{ span: 8 }}  >
							<ClientStyleBoardPreview
								styleBoard={dealInfo?.details?.styleboards?.[0]}
								stylistInfo={activeStylist}
							/>
						</Col>
						<Col xs={{span: 24}} md={{span: 12}} xl={{ span: 8 }} xxl={{ span: 9 }}  >
							<ClientCapsulesBlock
								stylistId={activeStylist?.id}
								capsulesList={
									!!dealInfo?.details?.capsules.length
										? dealInfo?.details?.capsules
										: []
								}
								elPerRow={1}
							/>
						</Col>
					</Swap>
				</Row>
			</BlockStyled>
			<Modal
				modalTitle="Are you sure you want to cancel this booking?"
				isModalOpen={isModalOpen}
				onCancel={handleCancel}
				onOk={handleOk}
			/>
		</>
	)
}

import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { getStylistPublicCapsules } from '@api/client/get-stylist-public-capsules'
import { authSelector } from '@store/auth'
import { useAppSelector } from '@store/index'
import { userSelector } from '@store/user'
import { CapsuleInfo, NavigationButtonInfo } from '@typings/types'

export function useStylistProfilePreviewPage() {
	const navigate = useNavigate()
	const { token } = useAppSelector(authSelector)
	const { userInfo } = useAppSelector(userSelector)

	const navigationButtons: NavigationButtonInfo[] = [
		{
			title: 'Preview',
			iconName: 'previewIcon',
			showListType: 'preview',
			handleClick: () => {},
		},
		{
			title: 'Edit',
			iconName: 'editIcon',
			showListType: 'edit',
			handleClick: () => {
				navigate(`/stylist-profile`)
			},
		},
	]

	const [capsulesList, setCapsulesList] = useState<CapsuleInfo[]>([])

	const loadCapsules = useCallback(async () => {
		let capsules = await getStylistPublicCapsules({
			token,
			stylistId: userInfo.id,
		})

		if (capsules) {
			capsules = capsules.sort(function (a, b) {
				return a.id > b.id ? -1 : 1
			})

			setCapsulesList(capsules)
		}
	}, [token, userInfo])

	useEffect(() => {
		loadCapsules()
	}, [])

	return {
		userInfo,
		stylistInfo: userInfo?.stylist,
		capsulesList,
		navigationButtons,
	}
}

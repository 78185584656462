import styled, { css } from 'styled-components'

import { colors, screenSize } from '@styles/index'
import { fontRoboto, regular13, regular14, regular16 } from '@styles/typography'

// TODO: Need refactoring here!

export const FlexBlock = styled.div<{
	width?: string
}>`
	display: flex;
	flex-direction: column;
	width: 100%;

	${({ width }) =>
		width &&
		css`
			max-width: ${width};
		`}
`

export const PlaceholderWrapper = styled.label<{
	hasValue?: boolean
	isError?: boolean
}>`
	${fontRoboto}
	${regular16}
	color: ${colors.blackTr64};
	position: absolute;
	z-index: 100;
	width: inherit;

	padding: 0 4px;
	pointer-events: none;
	transition: all 0.2s ease-in-out, font-size 0.2s ease-in-out;

	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	${({ hasValue, isError }) =>
		hasValue &&
		css`
			font-size: 1.3rem;
			color: ${isError ? '#ff0c63' : colors.blackTr48};
			top: -13px !important;
		`}

	${({ isError }) =>
		isError &&
		css`
			color: #ff0c63;
		`}

	@media (${screenSize.antXs}) {
		top: 10px;
	}
	@media (${screenSize.antSm}) {
		top: 15px;
	}
`

export const PlaceHolderBg = styled.span`
	background-color: ${colors.white};
`

export const Container = styled.div<{
	width?: string
	isError?: boolean
	type?: string
}>`
	position: relative;
	display: flex;
	align-items: center;

	background-color: ${colors.white};
	padding: 0 6px 0 16px;

	border-radius: 8px;
	border: 1px solid ${colors.blackTr12};

	${({ type }) =>
		type === 'textArea' &&
		css`
			border-radius: 8px;
			border: 1px solid ${colors.blackTr12};
		`}

	${({ isError }) =>
		isError &&
		css`
			border: 1px solid #ff0c63;
		`}

	width: 100%;
	${({ width }) =>
		width &&
		css`
			width: ${width};
		`}
`

export const IconContainer = styled.div`
	margin-right: 13px;
`

export const InputContainer = styled.div<{
	isError?: boolean
}>`
	position: relative;
	width: 100%;

	.PhoneInputCountry {
		display: none;
	}

	input {
		${fontRoboto}

		background: transparent;
		border: none;
		outline: none;
		color: ${colors.black};
		width: 100%;
	}

	/* Chrome, Safari, Edge, Opera */
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	/* Firefox */
	input[type='number'] {
		-moz-appearance: textfield;
	}

	&:focus-within,
	&:active {
		${PlaceholderWrapper} {
			top: -13px;
			font-size: 13px;
			color: ${({ isError }) => (isError ? '#ff0c63' : colors.blackTr48)};
		}
	}

	@media (${screenSize.antXs}) {
		padding: 10px 0;

		input {
			${regular14}
		}
	}
	@media (${screenSize.antSm}) {
		padding: 13px 0;

		input {
			${regular16}
		}
	}
`

export const TextAreaContainer = styled.div`
	position: relative;
	padding: 15px 0;
	width: 100%;

	textarea {
		border: none;
		resize: none;
		width: 100%;

		${fontRoboto}
		${regular16}

		color: ${colors.black};

		&:focus-within {
			outline: none;
		}
	}

	&:focus-within {
		${PlaceholderWrapper} {
			top: -13px;
			font-size: 13px;
			color: ${colors.blackTr48};
		}
	}
`

export const CheckBoxContainer = styled.div`
	display: flex;
	justify-content: flex-start;

	& input {
		width: 20px;
		height: 20px;
		border: 1px solid ${colors.blackTr16};
		border-radius: 3px;
		background: ${colors.white};

		margin-right: 12px;
	}
`

export const CheckBoxTextWrapper = styled.div`
	${fontRoboto}
	${regular16}
`

export const ErrorBlock = styled.div`
	${regular13}
	color: ${colors.error};
	margin-top: 5px;
	padding: 0 6px 0 16px;
`

import { screenSize } from '@styles/screen-sizes'
import { fontTenorSans } from '@styles/typography'
import { Block } from '@ui/index'
import styled, { css } from 'styled-components'

export const CompWrapper = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
`

export const UpdateImageWrapper = styled.div<{ imageUrl?: string }>`
	display: flex;
	justify-content: center;
	align-items: center;

	cursor: pointer;

	margin: 0 auto;

	background: rgba(0, 0, 0, 0.05);
	border-radius: 200px;

	${({ imageUrl }) =>
		imageUrl &&
		css`
			background-image: url(${imageUrl});
		`}
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;

	#upload-photo {
		opacity: 0;
		position: absolute;
		z-index: -1;
	}

	.ant-upload-select {
		display: block;
	}

	@media (${screenSize.antXs}) {
		width: 100px;
		height: 100px;

		label {
			width: 100px;
			height: 100px;
		}
	}
	@media (${screenSize.antSm}) {
		width: 100px;
		height: 100px;

		label {
			width: 100px;
			height: 100px;
		}
	}
	@media (${screenSize.antXl}) {
		width: 246px;
		height: 246px;

		label {
			width: 246px;
			height: 246px;
		}
	}
`

export const StyledLabel = styled.label<{ isAvatarAdded?: boolean }>`
	cursor: pointer;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 32px;

	width: 190px;
	height: 190px;

	border: 2px dashed rgba(0, 0, 0, 0.05);
	border-radius: 200px;

	${({ isAvatarAdded }) =>
		isAvatarAdded &&
		css`
			border: none;
		`}
`

export const UploadText = styled(Block)`
	${fontTenorSans}
	font-weight: 400;
	font-size: 18px;
	line-height: 24px;
	text-align: center;
`

export const EditPhotoWrapper = styled.div`
	margin-top: 24px;

	#upload-file {
		opacity: 0;
		position: absolute;
		z-index: -1;
	}
`

export const ErrorBlock = styled.div`
	${fontTenorSans}
	color: red;
	text-align: center;
`

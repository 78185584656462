import { Col, Image, Row } from 'antd'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import { Link, useParams } from 'react-router-dom'

import { ImageItemType, ItemInfo } from '@typings/types'
import {
	ArrowNext,
	ArrowPrev,
} from '@ui/gallery-buttons/gallery-buttons-styled'
import { Icon } from '@ui/icon'
import {
	Brand,
	CarouselWrapper,
	Container,
	FullScrIcon,
	HoverWrapper,
	InfoWrapper,
	Price,
	Title,
} from './gallery-block-styled'

type Props = {
	carouselRef: any
	items: ItemInfo[]
	imageList: ImageItemType[]
}

export function GalleryBlock({ carouselRef, items, imageList }: Props) {
	const { id: capsuleId } = useParams()

	const responsive = {
		superLargeDesktop: {
			// the naming can be any, depends on you.
			breakpoint: { max: 4000, min: 1601 },
			items: 3,
		},
		desktop: {
			breakpoint: { max: 1600, min: 1200 },
			items: 2,
		},
		desktopSmall: {
			breakpoint: { max: 1199, min: 1025 },
			items: 3,
		},
		tablet: {
			breakpoint: { max: 1024, min: 465 },
			items: 2,
			partialVisibilityGutter: 0,
		},
		mobile: {
			breakpoint: { max: 464, min: 0 },
			items: 1,
			partialVisibilityGutter: 0,
		},
	}

	return (
		<Row gutter={0} align="middle">
			<Col span={18} className="row-block-label">
				Clothing items
			</Col>
			<Col span={6} />

			<Col xs={{ span: 3 }} sm={{ span: 2 }}>
				{imageList.length > 1 && (
					<ArrowPrev
						leftIcon={<Icon color="#FFF" name="arrowDownIcon" />}
						onClick={() => carouselRef.current?.previous()}
						skin="white-outline"
						size="m"
						isFitContent
					/>
				)}
			</Col>
			<Col xs={{ span: 18 }} sm={{ span: 20 }} style={{ marginTop: 58 }}>
				<CarouselWrapper>
					<Carousel
						ref={(el) => (carouselRef.current = el)}
						responsive={responsive}
						containerClass="carousel-container"
						itemClass="carousel-item"
						centerMode={false}
						arrows={false}
						renderButtonGroupOutside={true}
					>
						{imageList.map((imageItem, index) => (
							<Link
								to={`/clothes/${imageItem.itemId}`}
								key={index}
								state={{
									item: items.find((item) => item.id === imageItem.itemId),
									capsuleId: capsuleId,
								}}
							>
								<Container data-clothid={imageItem.itemId}>
									<HoverWrapper>
										<Image
											src={imageItem.url}
											preview={{
												src: imageItem.previewUrl,
												mask: (
													<FullScrIcon>
														<Icon color="white" name="fullScreenIcon" />
													</FullScrIcon>
												),
											}}
										/>
									</HoverWrapper>
									<InfoWrapper>
										<Title>{imageItem.title}</Title>
										<Brand>{imageItem.brand}</Brand>
										<Price>
											{imageItem.price &&
												`${imageItem.currency} ${imageItem.price}`}
										</Price>
									</InfoWrapper>
								</Container>
							</Link>
						))}
					</Carousel>
				</CarouselWrapper>
			</Col>
			<Col xs={{ span: 3 }} sm={{ span: 2 }}>
				{imageList.length > 1 && (
					<ArrowNext
						leftIcon={<Icon color="#FFF" name="arrowDownIcon" />}
						onClick={() => carouselRef.current?.next()}
						skin="white-outline"
						size="m"
						isFitContent
					/>
				)}
			</Col>
		</Row>
	)
}

import { Col, Dropdown, Row } from 'antd'
import { Link } from 'react-router-dom'

import { Button, Icon } from '@ui/index'
import { HeaderCount } from '../../header-count/header-count'
import { HeaderTitle } from '../../header-title/header-title'
import { useHeader } from '../../use-header'
import {
	ProfileArrowBlock,
	ProfileWrapper,
	UserName,
	UserRole,
} from './header-desktop-styled'

export function HeaderDesktop() {
	const {
		token,
		counts,
		role,
		imageUrl,
		userName,
		handleProfile,
		handleLogOut,
	} = useHeader()

	return (
		<Row
			justify="space-between"
			gutter={24}
			align="middle"
			style={{ width: '100%', padding: '20px 48px' }}
		>
			<Col
				md={{ span: 24, order: 2 }}
				xl={{ span: 8, order: 0 }}
				style={{ padding: '24px 48px' }}
			>
				{token && <HeaderTitle />}
			</Col>

			<Col
				md={{ order: 0 }}
				xl={{ order: 1 }}
				flex="auto"
				style={{ display: 'flex', justifyContent: 'end' }}
			>
				{/*token &&
					counts.map((item, index) => <HeaderCount key={index} {...item} />)*/}
			</Col>
			<Col
				md={{ span: 11, order: 1 }}
				lg={{ span: 8 }}
				xl={{ span: 7, order: 2 }}
			>
				<Row justify="end" gutter={12} align="middle">
				{token &&
					counts.map((item, index) => <HeaderCount key={index} {...item} />)}
					<Col flex="0 1 120px">
						<UserName>{userName}</UserName>
						{role && <UserRole> StyleDyle {role}</UserRole>}
					</Col>
					<Link to={role ? `/${role}-profile` : '/login'}>
						<ProfileWrapper imageUrl={imageUrl}>
							{!imageUrl && <Icon name="userIcon" color="black" />}
						</ProfileWrapper>
					</Link>
					{token && (
						<Dropdown
							menu={{
								items: [
									{
										key: '1',
										label: (
											<Button skin="grey-link" size="m" onClick={handleProfile}>
												Profile
											</Button>
										),
									},
									{
										key: '2',
										label: (
											<Button skin="grey-link" size="m" onClick={handleLogOut}>
												Log Out
											</Button>
										),
									},
								],
							}}
							placement="bottomRight"
							arrow={{ pointAtCenter: true }}
						>
							<ProfileArrowBlock isFlexBlock margin="0 0 0 12px">
								<Icon name="arrowDownIcon" />
							</ProfileArrowBlock>
						</Dropdown>
					)}
				</Row>
			</Col>
		</Row>
	)
}
